import config from "./config";
import OrderColumnUtil from "../components/common/orderColumnUtil.js";
import OrderUtil from "../components/common/orderUtil";
import WebSocketUtil from "../components/common/webSocket/webSocketUtil";

let sortOrders = "s_id:asc";

class billService {

    static getBillPageColumnsConfiguration(disableProbateInvoiceStage = false) {
        const dropToInvoiceStageProps = disableProbateInvoiceStage === false ? [OrderUtil.orderStages.draft.value, OrderUtil.orderStages.proposal.value, OrderUtil.orderStages.order.value, OrderUtil.orderStages.deleted.value, OrderUtil.orderStages.onHold.value] : [];
        return {
            columns: [
                {
                    name: OrderUtil.findOrderStageFromLS(OrderUtil.orderStages.draft.value).value,
                    id: "draft",
                    stage: {
                        id: OrderUtil.orderStages.draft.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/order/list?psi=0&pl=25&f_st=-1",
                    dataSourceUrlRequestParameters: "&view=bill&sort=" + sortOrders,
                    columnItemDetailUrl: config.baseURL + "api/v1/order/",
                    headerIconFileName: "group-order--color",
                    itemsPropertyName: "orders",
                    creatable: false,
                    userSpecific: {
                        key: "f_auid"
                    },
                    filter: OrderColumnUtil.getFilter([-1], false),
                    sort: OrderColumnUtil.getSortConfigWithSelection(sortOrders),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "order",
                            "objectValues": [
                                OrderUtil.orderStages.proposal.value,
                                OrderUtil.orderStages.order.value,
                                OrderUtil.addInvoiceForAllRolesExceptFuneralAdvisorAndSalesExternal(),
                                OrderUtil.orderStages.deleted.value,
                                OrderUtil.orderStages.onHold.value,
                                OrderUtil.addOldStageOnlyForAdminUser(OrderUtil.orderStages.archived.value, OrderUtil.orderStages.draft.value)
                            ],
                            "action": {
                                "name": "updateOrder",
                                "value": -1
                            }
                        }
                    },
                    listener: {
                        id: "orders_pre_draft",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                                types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                                stages: [
                                    -1
                                ]
                            }
                        ]
                    }
                },
                {
                    name: OrderUtil.findOrderStageFromLS(OrderUtil.orderStages.proposal.value).value,
                    id: "proposal",
                    stage: {
                        id: OrderUtil.orderStages.proposal.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/order/list?psi=0&pl=25&f_st=1",
                    dataSourceUrlRequestParameters: "&view=bill&sort=" + sortOrders,
                    columnItemDetailUrl: config.baseURL + "api/v1/order/",
                    headerIconFileName: "group-order--color",
                    itemsPropertyName: "orders",
                    creatable: false,
                    userSpecific: {
                        key: "f_auid"
                    },
                    filter: OrderColumnUtil.getFilter([1], false),
                    sort: OrderColumnUtil.getSortConfigWithSelection(sortOrders),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "order",
                            "objectValues": [
                                OrderUtil.orderStages.draft.value,
                                OrderUtil.orderStages.order.value,
                                OrderUtil.addInvoiceForAllRolesExceptFuneralAdvisorAndSalesExternal(),
                                OrderUtil.orderStages.deleted.value,
                                OrderUtil.orderStages.onHold.value,
                                OrderUtil.addOldStageOnlyForAdminUser(OrderUtil.orderStages.archived.value, OrderUtil.orderStages.proposal.value)
                            ],
                            "action": {
                                "name": "updateOrder",
                                "value": 1
                            }
                        }
                    },
                    listener: {
                        id: "orders_draft",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                                types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                                stages: [
                                    1
                                ]
                            }
                        ]
                    }
                },
                {
                    name: OrderUtil.findOrderStageFromLS(OrderUtil.orderStages.order.value).value,
                    id: "order",
                    stage: {
                        id: OrderUtil.orderStages.order.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/order/list?psi=0&pl=25&f_st=2",
                    dataSourceUrlRequestParameters: "&view=bill&sort=" + sortOrders,
                    columnItemDetailUrl: config.baseURL + "api/v1/order/",
                    headerIconFileName: "group-order--color",
                    itemsPropertyName: "orders",
                    creatable: false,
                    userSpecific: {
                        key: "f_auid"
                    },
                    filter: OrderColumnUtil.getFilter([2], false),
                    sort: OrderColumnUtil.getSortConfigWithSelection(sortOrders),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "order",
                            "objectValues": [
                                OrderUtil.orderStages.draft.value,
                                OrderUtil.orderStages.proposal.value,
                                OrderUtil.addInvoiceForAllRolesExceptFuneralAdvisorAndSalesExternal(),
                                OrderUtil.orderStages.deleted.value,
                                OrderUtil.orderStages.onHold.value,
                                OrderUtil.addOldStageOnlyForAdminUser(OrderUtil.orderStages.archived.value, OrderUtil.orderStages.order.value)
                            ],
                            "action": {
                                "name": "updateOrder",
                                "value": 2
                            }
                        }
                    },
                    listener: {
                        id: "orders_final",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                                types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                                stages: [
                                    2
                                ]
                            }
                        ]
                    }
                },
                {
                    name: OrderUtil.findOrderStageFromLS(OrderUtil.orderStages.invoice.value).value,
                    id: "invoice",
                    stage: {
                        id: OrderUtil.orderStages.invoice.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/order/list?psi=0&pl=25&f_st=3",
                    dataSourceUrlRequestParameters: "&view=bill&sort=" + sortOrders,
                    columnItemDetailUrl: config.baseURL + "api/v1/order/",
                    headerIconFileName: "group-order--color",
                    itemsPropertyName: "orders",
                    userSpecific: {
                        key: "f_auid"
                    },
                    filter: OrderColumnUtil.getFilter([3], false),
                    sort: OrderColumnUtil.getSortConfigWithSelection(sortOrders),
                    creatable: false,
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "order",
                            "objectValues": [
                                ...dropToInvoiceStageProps
                            ],
                            "action": {
                                "name": "updateOrder",
                                "value": 3
                            }
                        }
                    },
                    listener: {
                        id: "orders_billed",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                                types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                                stages: [
                                    3
                                ]
                            }
                        ]
                    }
                },
                {
                    name: OrderUtil.findOrderStageFromLS(OrderUtil.orderStages.archived.value).value,
                    id: "archived",
                    stage: {
                        id: OrderUtil.orderStages.archived.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/order/list?psi=0&pl=25&f_st=4",
                    dataSourceUrlRequestParameters: "&view=bill&sort=" + sortOrders,
                    columnItemDetailUrl: config.baseURL + "api/v1/order/",
                    headerIconFileName: "group-order--color",
                    itemsPropertyName: "orders",
                    creatable: false,
                    userSpecific: {
                        key: "f_auid"
                    },
                    filter: OrderColumnUtil.getFilter([4], false),
                    sort: OrderColumnUtil.getSortConfigWithSelection(sortOrders),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "order",
                            "objectValues": [
                                OrderUtil.addOldStageOnlyForAdminUser(OrderUtil.orderStages.invoice.value, OrderUtil.orderStages.archived.value)
                            ],
                            "action": {
                                "name": "updateOrder",
                                "value": 4
                            }
                        }
                    },
                    listener: {
                        id: "orders_archived",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                                types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                                stages: [
                                    4
                                ]
                            }
                        ]
                    }
                },
                {
                    name: OrderUtil.findOrderStageFromLS(OrderUtil.orderStages.deleted.value).value,
                    id: "deleted",
                    stage: {
                        id: OrderUtil.orderStages.deleted.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/order/list?psi=0&pl=25&f_st=5",
                    dataSourceUrlRequestParameters: "&view=bill&sort=" + sortOrders,
                    columnItemDetailUrl: config.baseURL + "api/v1/order/",
                    headerIconFileName: "group-order--color",
                    itemsPropertyName: "orders",
                    creatable: false,
                    userSpecific: {
                        key: "f_auid"
                    },
                    filter: OrderColumnUtil.getFilter([5], false),
                    sort: OrderColumnUtil.getSortConfigWithSelection(sortOrders),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "order",
                            "objectValues": [
                                OrderUtil.orderStages.draft.value,
                                OrderUtil.orderStages.proposal.value,
                                OrderUtil.orderStages.order.value,
                                OrderUtil.orderStages.onHold.value,
                                OrderUtil.addOldStageOnlyForAdminUser(OrderUtil.orderStages.invoice.value, OrderUtil.orderStages.deleted.value)
                            ],
                            "action": {
                                "name": "updateOrder",
                                "value": 5
                            }
                        }
                    },
                    listener: {
                        id: "orders_paid",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                                types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                                stages: [
                                    5
                                ]
                            }
                        ]
                    }
                }
            ],
            cardDetailViewTabCollection: OrderColumnUtil.getCardDetailViewTabCollection()
        };
    }
}

export default billService;
