class Swedish {
    static getTexts = {
        notFound: {
            permissionDeniedMessage: "Inget att se här",
            instructions: "Vänligen testa att klicka dig vidare eller att söka. Du kan också ",
            contactAdmin: "Om du tror att du ser denna sida på grund av ett fel, kontakta en administratör.",
            rootPageRedirect: "logga ut och gå tillbaka till startsidan"
        },
        login: {
            username: "Email",
            password: "Lösenord",
            loginBtn: "Logga in",
            forgotPassword: "Glömt lösenord?"
        },
        forgotPassword: {
            loginLink: "Logga in",
            header: "Glömt lösenord",
            text: "Skriv din e-postadress nedan så skickar vi en länk du kan följa för att uppdatera ditt lösenord.",
            resetBtn: "Återställ lösenord",
            resetTextPart1: "Vi har skickat ett mail till ",
            resetTextPart2: ". Skriv in koden nedan för att uppdatera ditt lösenord.",
            resetCode: "Kod för nytt lösenord",
            resetCodePlaceholder: "123456",
            newPassword: "Nytt lösenord",
            repeatPassword: "Repetera lösenord",
            changePasswordBtn: "Uppdatera lösenord"
        },
        formValidation: {
            lowerCaseCantBeEmpty: "får inte vara tomt"
        },
        passwordInstruction: {
            characterLength: "Minst 8 tecken",
            caseDetails: "Stora och små bokstäver",
            numbers: "Siffror",
            specialCharMessage: "Specialtecken, till exempel: %, &, _",
            passwordNotMatch: "Lösenorden är inte likadana",
            passwordRules: "Lösenordet uppfyller inte lösenordsreglerna"
        },
        confirmUser: {
            header: "Ange nytt lösenord",
            newPassword: "Nytt lösenord",
            submit: "Spara",
            failedPasswordReset: "Något gick fel - försök logga in igen!"
        },
        header: {
            convert: "Sälja",
            organise: "Leverera",
            bill: "Fakturera",
            dashboard: "Dashboard",
            customers: "Kunder",
            more: "Mer",
            partners: "Partner",
            azets: "Azets",
            cases: "Ärenden",
            orders: "Uppdrag",
            calls: "Samtal",
            products: "Produkter",
            locations: "Platser",
            contacts: "Kontakter",
            search: "Sök",
            notifications: "Notiser",
            help: "Hjälp",
            settings: "Inställningar",
            myAccount: "Mitt konto",
            logOut: "Logga ut",
            delete: "Ta bort",
        },
        products: {
            products: "Produkter",
            detailsTabContent: {
                title: "Titel",
                description: "Beskrivning",
                specification: "Specifikation",
                slug: "Slug",
                seoTitle: "SEO-titel",
                seoDescription: "SEO-beskrivning",
                type: "Typ",
                category: "Kategori",
                price: "Pris",
                maxorderamount: "Max antal per uppdrag",
                showproductinpubliclistings: "Visa produkt i offentliga listor",
                deleteproduct: "Ta bort",
                linkpreview: "Förhandsgranska länk",
                updateDetails: "Spara",
                saveDetails: "Spara detaljer",
                status: "Status",
                complete: "Komplett",
                incomplete: "Inkomplett",
                availability: "Tillgänglighet",
                noVariantsAvailable: "Finns inga varianter",
                enableFreeText: "Aktivera fritextfält (visas på uppdrag)",
                enableDisbursement: "Aktivera utbetalning"
            },
            picturesTabContent: {
                altText: "ALT-text",
                update: "Uppdatera",
                default: "Huvudbild",
                isDefault: "Huvudbild",
                deletepicture: "Ta bort",
                addpicture: "Lägg till bild",
                product: "Produkt",
                dropfilehere: "Släpp fil här för att ladda upp",
                oR: "eller",
                save: "Lägg till",
                noVariantsAvailable: "Finns inga varianter"
            },
            childrenTabContent: {
                addchildproduct: "Lägg till barnprodukt"
            },
            tabs: {
                details: "Detaljer",
                pictures: "Bilder",
                children: "Barn"
            }
        },
        locations: {
            locations: "Platser",
            yes: "Ja",
            no: "Nej",
            unknown: "Okänd",
            detailsTabContent: {
                locationName: "Platsnamn",
                comment: "Kommentar",
                address: "Adress",
                postalCode: "Postnummer",
                city: "Stad",
                slug: "Slug",
                seoTitle: "SEO-titel",
                seoDescription: "SEO-beskrivning",
                linkpreview: "Förhandsgranska länk",
                showlocationinpubliclistings: "Publicera platsen i listor",
                type: "Typ",
                picture: "Bild",
                place: "Plats",
                imageURL: "Bild-URL",
                altText: "ALT-text",
                dropfilehere: "Släpp fil här för att ladda upp",
                update: "Uppdatera bild",
                deletelocation: "Ta bort",
                updateDetails: "Spara",
                saveDetails: "Spara detaljer",
                yearBuilt: "År byggt",
                placeholder: "Ingen"
            },
            mapTabContent: {
                latitude: "Latitud",
                longitude: "Longitud",
                latitudeAndLongitude: "Latitud, longitud",
                saveMap: "Spara"
            },
            typeTabContent: {
                updateDetails: "Spara"
            },
            equipmentTabContent: {
                coffincarriers: "Kistbärare",
                updateEquipment: "Spara"
            },
            contactTabContent: {
                addContact: "Lägg till"
            },
            tabs: {
                details: "Detaljer",
                map: "Karta",
                types: "Typer",
                equipment: "Utrustning",
                contact: "Kontakter"
            },
            locationTypes: {
                country: "Land",
                county: "Län",
                municipality: "Kommun",
                city: "Ort"
            }
        },
        customers: {
            tabs: {
                details: "Uppgifter",
                customerPackage: "Paket",
                documents: "Dokument"
            },
            package: {
                name: "Namn",
                document: "Dokument",
                orderDate: "Uppdragsdatum",
                order: "Uppdrag",
                product: "Produkt",
                price: "Pris",
                partner: "Partner",
                partnerServices: "Partnertjänster",
                fenixServices: "Fenixtjänster",
                payments: "Betalning",
                noPackage: "Finns inga paket"
            }
        },
        partners: {
            tabs: {
                details: "Uppgifter",
                partnerUsers: "Användare",
                partnerCustomers: "Kunder",
                partnerServices: "Partnertjänster",
                fenixServices: "Fenix-tjänster",
                partnerProducts: "Produkter",
                partnerPackages: "Paket",
                partnerPackageBatches: "Paketomgångar",
            },
            name: "Namn",
            personNumber: "Personnummer",
            partnerName: "Partnernamn",
            selectToAdd: "Välj för att lägga till {ITEM}...",
            dataSharingConsent: "Tillåtelse att dela data",
            profile: {
                title: "Profil",
                phoneNumber: "Telefon",
                email: "Email",
                address: "Adress",
                postalCode: "Postnummer",
                city: "Stad",
                county: "Län",
                country: "Land",
                description: "Beskrivning",
                partnerProfile: "Partnerprofil",
                addedInfo: "{CONTENT} tillagd",
                updatedInfo: "{CONTENT} uppdaterad",
                deletedInfo: "{CONTENT} borttagen",
                notFound: "{CONTENT} kunde inte hittas",
                landingPageUrl: "Landningssida-URL",
            },
            users: {
                user: "Användare",
                deletePopupTitle: "Är du säker på att du vill ta bort \"USER_NAME\"?",
                role: "[Role]",
            },
            customers: {
                customer: "Kund",
                deletePopupTitle: "Är du säker på att du vill ta bort \"CUSTOMER_NAME\"?",
                partnerUser: "Partneranvändare",
                partnerPackageInvitations: "Partnerpaketinbjudningar",
                customerPackages: "Kundpaket",
                showDetails: "Visa detaljer",
            },
            partnerServices: {
                partnerService: "Partnertjänst",
                description: "Beskrivning",
                bookingUrl: "Möteslänk",
            },
            fenixServices: {
                templateId: "Mall-id",
                fenixService: "Fenix-tjänst",
            },
            products: {
                products: "Produkter",
                product: "Produkt",
                price: "Pris",
                stripePriceId: "Stripe price id",
                stripeCouponId: "Stripe coupon id",
                paymentModeType: "Betalningsfrekvens",
                paymentMode: "Betalas av",
            },
            packages: {
                partnerServices: "Partnertjänster",
                fenixServices: "Fenix-tjänster",
                products: "Produkter",
                package: "Paket",
                price: "Pris",
                description: "Beskrivning",
            },
            batches: {
                packageBatches: "Paketomgångar",
                packageBatch: "Paketomgång",
                dropdownTitle: "Välj {ITEM} för att hämta paketomgångar",
                batchStatus: "Status",
                totalCredits: "Totalt antal paket",
                usedCredits: "Använda paket",
                totalBonusCredits: "Totalt antal bonuspaket",
                usedBonusCredits: "Använda bonuspaket"
            }
        },
        azets: {
            subMenuTitle: "Azets-dokument",
            receivedByFenix: "Mottagen av Fenix Family",
            documentName: "Dokumenttitel",
            documentUrl: "Dokumentlänk",
            documentId: "ID",
            customerName: "Namn",
            status: "Status",
            documentStatus: "Dokumentstatus",
            popup: {
                receivedByFenixTitle: "Vill du markera [DOCUMENT_DETAILS] som mottagen av Fenix Family?",
            }
        },
        cases: {
            cases: "Ärenden",
            newCardForAdd: {
                mainContact: "(Namnlös)",
                deceased: "(Namnlös avliden)",
                new: "Ny"
            },
            notes: {
                addNote: "Lägg till",
                addNoteTooltip: "Lägg till anteckning",
                notePlaceholder: "Anteckna här...",
                shortNotePlaceholder: "Anteckna här...",
                reset: "Återställ",
                resetTooltip: "Återställ textfält",
                deleteNoteTooltip: "Ta bort anteckning",
                emptyNotesMessage: "Nya anteckningar visas här"
            },
            statusTabContent: {
                newCase: "Nytt ärende",
                mergeWithCase: "Sammanfoga med ärende",
                merge: "Sammanfoga",
                typeToSearch: "Skriv för att söka...",
                createOrder: "Skapa uppdrag",
                create: "Skapa",
                callSingular: "Samtal",
                callPlural: "Samtal",
                location: "Plats",
                phone: "Telefon",
                caller: "Uppringare",
                contactSingular: "Kontakt",
                contactPlural: "Kontakter",
                contactName: "Namn",
                contactRelationship: "Relation med avliden",
                deceased: "Avliden",
                deceasedName: "Namn",
                personalIdentityNumber: "Personnummer",
                status: "Status",
                labels: "Etiketter",
                caseCardNote: "Kortanteckning",
                caseCardNotePlaceholder: "Skriv kortanteckning här...",
                owner: "Ägare",
                collaborator: "Medarbetare",
                caseCreated: "Ärende skapat",
                relatedCases: "Andra ärenden",
                caseDeadline: "Deadline",
                caseDeadlineHelpCaseCreated: "Datum ärendet skapades",
                caseDeadlineHelpCeremonyDate: "Ceremonidatum",
                caseDeadlineHelp30DaysOverDateOfPassing: "Dödsdatum +30 dagar",
                caseDeadlineHelpNone: "Ingen deadline än",
                deadline: {
                    resetHelpMsg: "Deadline kommer att beräknas när fallet sparas",
                    reset: "Nollställ",
                    edit: "Redigera",
                    cancel: "Avbryt"
                },
                cid: "CID",
                noLocation: "Ingen plats",
                noCaller: "Ingen uppringare",
                averageScore: "Medelpoäng",
                responses: "Svar",
                pending: "Väntar på svar",
                todoSingular: "Todo",
                todoPlural: "Todos",
                customerTodoSingular: "Todo",
                customerTodoPlural: "Todos",
                cardTodoSingular: "todo",
                cardTodoPlural: "todos",
                todoButtonAdd: "Egen todo",
                todoDoneSingular: "Klar",
                todoDonePlural: "Klara",
                todoDelete: "Ta bort",
                todoStatusNotdone: "Markera som klar",
                todoStatusDone: "Klar",
                todoTemplate: "Todo-mallar",
                compensationPrel: "Prel. ersättning",
                compensation: "Ersättning",
                compensationInvoice: "Ersättning att fakturera",
                aboutCompensation: "Om ersättning"
            },
            deceasedTabContent: {
                name: "Namn",
                firstName: "Förnamn",
                lastName: "Efternamn",
                dateOfBirth: "Födelsedatum",
                country: "Land",
                county: "Län",
                referredToCoroner: "Skickad till obduktion",
                readyForCollection: "Klar för hämtning",
                deathRegistered: "Dödsfall registrerat",
                greenFormWith: "Grönt formular med",
                address: "Adress",
                address2: "Adress 2",
                postalCode: "Postnummer",
                city: "Postort",
                memberOfSwedishChurch: "Medlem Svenska kyrkan",
                pacemaker: "Pacemaker",
                personalIdentityNumber: "Personnummer",
                getDetailsBtn: "Hämta detaljer",
                dateOfPassing: "Dödsdatum",
                useResidentialAddress: "Använd hemadress",
                locationOfBody: "Plats för kroppen, bårhus",
                locationOfBodyPickup: "Plats för kroppen, bårhämtning",
                locationOfBodyPickupNotes: "Plats för kroppen, bårhämtning anteckningar",
                dateOfBodyPickup: "Datum för hämtning",
                timeOfBodyPickup: "Tidpunkt för hämtning",
                transporterPickup: "Transportör, bårhämtning",
                bodyDestination: "Förd till",
                parishName: "Församling",
                greenFormWithOptions: {
                    family: "Familj",
                    registrar: "Registrator",
                    notIssued: "Ej registrerad",
                    unknown: "Okänt",
                    notRequired: "Ej obligatorisk",
                    fenix: "Fenix",
                    partnerFD: "Partner FD"
                },
                sex: "Kön",
                genderOptions: {
                    male: "Man",
                    female: "Kvinna"
                },
                maritalStatus: "Civilstånd",
                maritalStatusOptions: {
                    marriedOrCivilPartnership: "Gift eller partnerskap",
                    widowOrWidowerOrCivilPartner: "Skild eller änkebliven",
                    single: "Ej gift"
                },
                infectionStatus: "Infektionsstatus",
                infectionStatusOptions: {
                    notInfectious: "Inte smittsam",
                    infectiousCOVID: "Infectious COVID",
                    infectiousOther: "Infektiös annan",
                    unknown: "Okänt"
                },
                lastOccupation: "Senaste sysselsättning",
                timeOfPassing: "Dödsdatum",
                locationOfPassing: "Dödsfallsplats",
                locationOfPassingOptions: {
                    home: "Hem",
                    hospital: "Sjukhus",
                    nursingHome: "Äldreboende",
                    hotel: "Hotell",
                },
                furtherExaminationRequired: "Vidare undersökning krävs",
                detailsOfFurtherExamination: "Detaljer för vidare undersökning",
                releaseForm: "Frigivningsformulär",
                releaseFormOptions: {
                    unknown: "Okänt",
                    required: "Obligatorisk",
                    notRequired: "Ej obligatorisk",
                },
                causeOfDeath: "Dödsorsak",
                causeOfDeathOptions: {
                    natural: "Naturlig",
                    unnatural: "Ej naturlig",
                },
                cremationPapers: "Kremationsintyg",
                cremationPaperOptions: {
                    unknown: "Okänt",
                    notRequired: "Ej obligatorisk",
                    fourOnly: "[4 only]",
                    cremationPaperFourAndFive: "[4 & 5]",
                    sixCoroner: "[6 (coroner)]",
                },
                cremationPaperPaidBy: "Kremationsintyg betalas av (används ej)",
                cremationPaperPaidByOptions: {
                    customer: "Kund",
                    funeralPartnerByCheque: "Byråpartner med check",
                    funeralPartnerOther: "Byråpartner med annat betalningssätt",
                    invoicedToFenix: "Fakturerat till Fenix",
                    fenixByPhone: "Fenix via telefon",
                    notApplicable: "Inte tillämpbart",
                },
                paymentDetailsForCremationPapers: "Betalningsuppgifter för kremationsintyg (används ej)",
                pacemakerOptions: {
                    yes: "Ja",
                    no: "Nej",
                    unknown: "Okänt",
                    yesRemoved: "Ja, borttaget",
                    yesNotRemoved: "Ja, ej borttaget",
                },
                otherDetails: "Andra uppgifter",
                residentialAddress: "Bostadsadress",
                addressOfPassing: "Dödsfallsadress",
                postalCodeOfPassing: "Dödsfallspostnummer",
                cityOfPassing: "Dödsfallsort",
                countyOfPassing: "Dödsfallslän",
                countryOfPassing: "Dödsfallsland",
                phoneOfPassing: "Dödsfallstelefon",
                arrangementDetails: "[Arrangement details]",
                cremation1Details: "[Cremation 1 Details]",
                relativesAndExecutorsInformed: "Anhöriga och exekutor informerade",
                relativesAndExecutorsInformedSelectOptions: {
                    allInformed: "Alla informerade",
                    allNotInformed: "Alla ej informerade"
                },
                notInformedDetails: "Detaljer för ej informade",
                cremationObjections: "Kremationsinvändningar",
                objectionsDetails: "Invändningsdetaljer",
                ashesDisposalTypeForCrem1: "Askgravsättningstyp (cremation1)",
                ashesDisposalTypeForCrem1SelectOptions: {
                    ashesToScattered: "Aska ska spridas eller gravsättas",
                    dealtByCrematorium: "Annars hanteras det av krematoriet",
                    collectedFromCrematorium: "Askan hämtas på krematoriet",
                    awaitingForDecision: "Askan väntar på beslut"
                },
                ashesInstructionsForCrem1: "Askinstruktioner (cremation1)",
                bodySize: "Kroppsstorlek",
                bodySizeOptions: {
                    standard: "Standard",
                    overSize: "Extra stor",
                    unknown: "Okänt"
                },
                bodySizeNotes: "Anteckningar om kroppen",
            },
            relativeTabContent: {
                contactName: "Namn",
                name: "Namn",
                firstName: "Förnamn",
                lastName: "Efternamn",
                dateOfBirth: "Födelsedatum",
                country: "Land",
                county: "Län",
                phoneNumber: "Telefonnummer",
                mobileNumber: "Mobilnummer",
                email: "E-post",
                billingContact: "Fakturamottagare",
                billingAddress: "Fakturaadress",
                useForBillingContact: "Fakturamottagare",
                otherDetails: "Andra uppgifter",
                relationship: "Relation med avliden",
                personalIdentityNumber: "Personnummer",
                address: "Adress",
                address2: "Adress 2",
                postalCode: "Postnummer",
                city: "Postort",
                mainContact: "Kontaktperson",
                guest: "Gäst",
                deleteContact: "Ta bort kontakt",
                noContacts: "Inga kontakter",
                addContact: "Lägg till",
                guestDetails: "Gästdetaljer",
                untitledContact: "(Namnlös)",
                ukContactProperties: {
                    ashesReceiver: "Askmottagare",
                    celebrant: "Officiant",
                    usualDoctor: "Husdoktor",
                    lastDoctor: "Senaste doktor",
                    cremCertificateInspector: "Inspektör för kremationsintyg",
                    cremApplicant: "Ansökare för crem 1",
                    hearseViaAddress: "[Hearse via address]",
                    churchServiceContact: "[Church service contact]",
                    consignee: "[Consignee]",
                },
                ukPaymentMethod: "Ekonomiskt stöd (DWP)",
                ukPaymentMethodOptions: {
                    customerPaying: "Kund betalar",
                    deceasedBankAccount: "Avlidens bankkonto",
                    dwpApplication: "DWP-ansökan"
                },
                nationalInsuranceNumber: "Nationellt försäkringsnummer",
                financialAssistanceDetails: "Betalningsanmärkningar och status",
                read: "Read",
                write: "Write",
                none: "None",
                invitedOrders: "Delning med kunder",
                notInvitedMessage: "Uppdraget är inte delat med den här kunden",
                deletePopupTitle: "Är du säker på att du vill sluta dela uppdraget med kunden",
                companyName: "Företagsnamn",
                organisationNumber: "Organisationsnummer",
                organisationName: "Organisationsnamn"
            },
            orderTabContent: {
                funeralCaseOrderContent: {
                    addOrder: "Lägg till uppdrag",
                    billingContact: "Fakturamottagare",
                    case: "Ärende",
                    status: "Status",
                    addContact: "Lägg till",
                    saveOrder: "Spara",
                    selectRelative: "Välj kontakt...",
                    emailSummary: "E-post",
                    deleteOrder: "Ta bort",
                    finalPrice: "Pris",
                    quantity: "Antal",
                    customProduct: "Anpassad produkt",
                    allMemorialFunds: "Alla minnesfonder",
                    product: "Produkt",
                    orderNote: "Uppdragsanteckning",
                    addOrderCardNote: "Lägg till uppdragsanteckning",
                },
                ukCaseOrderTabContent: {
                    disbursementPayment: "Betalning av utlägg",
                },
                funeralServiceMusicPlaceholder: "[Please add the chosen tracks and when they will be played]",
                pricePlaces: {
                    popup: {
                        addOrder: "Lägg till uppdrag [ORDER_NAME]",
                        priceRegion: "Prisregion",
                        createOrder: "Skapa order",
                        standardPrice: "Standardpris",
                        placeChangeOrderUpdate: "När du ändrar prisregion kommer produktpriserna att räknas om",
                        changePriceRegion: "Ändra prisregion",
                    },
                    fetchingPlaces: "Hämtar prisregioner",
                    unknown: "Okänd",
                    priceRegionButtonToolTip: "Byt prisregion",
                    dropdown: {
                        defaultOption: "Standardpris"
                    }
                }
            },
            callsTabContent: {
                header: "Koppla samtal",
                search: "Sök samtal...",
                removeCall: "Ta bort från ärende"
            },
            notesTabContent: {
                addNote: "Lägg till",
                reset: "Nollställ",
                shortcutAddNote: "Lägg till anteckning med Ctrl+Enter"
            },
            guestTabContent: {
                firstName: "Förnamn",
                lastName: "Efternamn",
                guests: "Anmälningar",
                totalGuests: "Totalt",
                rsvpYes: "Ja",
                rsvpNo: "Nej",
                registrationDate: "Anmälningsdatum",
                maxRegistrations: "Max anmälningar",
                name: "Namn",
                diet: "Kost",
                email: "Email",
                phone: "Telefon",
                registered: "Registrerad",
                confirmed: "Bekräftad",
                notConfirmed: "Inte bekräftad än",
                rsvp: "Kommer",
                addNewGuest: "Lägg till gäst",
                addGuest: "Lägg till gäst...",
                newContact: "Ny kontakt",
                existingContact: "Befintlig kontakt",
                deleteGuest: "Ta bort gäst",
                dietaryRestrictions: "Särskilda kostbehov",
                addDietaryRestriction: "Välj särskilt kostbehov...",
                yes: "Ja",
                no: "Nej",
                unknown: "Okänt",
                plusOnes: "Antal +1",
                untitledGuest: "(Namnlös)",
                sendGuestList: "Skicka gästlista",
                emailSendConfirmationTitle: "Vill du {send} e-post till {guest}?",
                tooManyRegistered: "Fler registrerade än max antal tillåtna",
                guestRestrictionPopup: {
                    continue: "Vill du fortsätta?",
                    exceedsMaxGuestAndRSVPDate: "Du har lagt till fler gäster än tillåtet, samt anmälningsdatum har passerats.",
                    exceedsMaxGuest: "Max antal tillåtna gäster är {limit}.",
                    exceedsRSVPDate: "Anmälningsdatum {limit} has passerats.",
                    leftBtnTitle: "Ok"
                }
            },
            memorial: {
                viewMemorial: "Besök minnessida",
                published: "Publicerad",
                slug: "Permalänk",
                fund: "Minnesfond",
                visitFund: "Besök",
                default: "Standard",
                custom: "Anpassad",
                picture: "Bild",
                pictureURL: "Bild-URL",
                pictureURLPlaceholder: "Ange bild-URL...",
                updatePicture: "Uppdatera bild",
                deceasedPicture: "Bild avliden",
                deceasedPictureAlt: "Vald bild avliden",
                deceasedPictureHelp: "Kvadratisk JPG eller PNG, minst 275x275px",
                backgroundPicture: "Bakgrundsbild",
                backgroundPictureAlt: "Vald bakgrundsbild",
                backgroundPictureHelp: "JPG eller PNG, minst 1440x600px",
                obituary: "Dödsruna",
                obituaryPlaceholder: "Ange dödsruna...",
                memories: {
                    empty: "Inga delade minnen att visa än",
                    emptyReported: "Inga flaggade minnen än",
                    title: "Minne",
                    titlePlural: "Minnen",
                    reportedOnly: "Flaggade",
                    all: "Alla",
                    reported: "Flaggad",
                    pictureAlt: "Minnesbild",
                    edit: "Redigera",
                    editTooltip: "Redigera minne",
                    delete: "Ta bort minne",
                    editMemory: "Redigera minne",
                    memoryStatus: "Status",
                    memoryStatusNormal: "Ej flaggad",
                    memoryStatusReported: "Flaggad",
                    memoryAuthor: "Författare",
                    memoryAuthorPlaceholder: "Ange författare...",
                    memoryText: "Minnestext",
                    memoryTextPlaceholder: "Ange minnestext...",
                    memoryMediaURL: "Media-URL",
                    memoryMediaURLPlaceholder: "Ange media-URL...",
                    numberOfLikes: "Antal som gillar",
                    deleteMemoryPopup: {
                        header: "Minne kommer tas bort",
                        content: "Är du säker på att du vill ta bort '{name}'?]",
                    },
                    deleteEmailSubscriptionPopup: {
                        header: "Prenumeration kommer tas bort",
                        content: "Är du säker på att du vill ta bort '{name}'?]",
                    },
                    editWarning: {
                        memory: "Du har osparade ändringar. Spara eller avbryt för att ändra andra minnen.",
                    }
                },
                emails: {
                    empty: "Inga prenumeranter än",
                    title: "E-postprenumerant",
                    titlePlural: "E-postprenumeranter",
                    typeEmail: "Ange e-postadress...",
                    addSubscription: "Lägg till",
                    edit: "Redigera",
                    editTooltip: "Redigera prenumerant",
                    delete: "Ta bort prenumerant"
                }
            },
            dashboard: {
                case: "Ärende",
                deceasedName: "Avlidens namn",
                deceasedPersonalNo: "Avl. personnummer",
                deceasedDOB: "Avl. födelsedatum",
                customerName: "Kund",
                fsArranger: "Rådgivare",
                funeralServiceDate: "Ceremonidatum",
                balanceDue: "Att betala",
                ukCurrencySign: "£",
                returnDeadline: "[Return deadline]",
                fdPartner: "[FD partner]",
                ashesInstruction: "[Ashes instruction]",
                overdueTodoTitle: "[Overdue todo]",
                overdueDate: "[Overdue date]",
                numberOfOverdueDays: "[No. days overdue]",
                todos: "Todos",
                orders: "Uppdrag",
                listType: {
                    invoiceNotIssuedCases: "[Invoice not issued]",
                    invoiceNotPaidCases: "[Invoice not paid]",
                    upcomingFuneralCases: "[Upcoming funeral]",
                    passedAndNonInvoicedCases: "[Passed ceremony date and non-invoiced cases]",
                    attendedAshesReturn: "[Attended ashes return]",
                    unattendedAshesReturn: "[Unattended ashes return]",
                    overdueTodo: "[Overdue todo]",
                },
                button: {
                    initial: "Första {COUNT}",
                    next: "Nästa {count}",
                    previous: "Föregående {COUNT}",
                    fullList: "Hela listan",
                },
                of: "av",
            },
            card: {
                callFrom: "Samtal från: ",
                callerUnknown: "okänd"
            },
            footerForCase: {
                saveCase: "Spara",
                selectRelative: "Välj kontakt...",
                emailSummary: "E-post",
                deleteCase: "Ta bort",
                showNotes: "Visa anteckningar"
            },
            tabs: {
                status: "Status",
                deceased: "Avliden",
                relative: "Kontakt",
                relativePluralized: "Kontakter",
                order: "Uppdrag",
                neworder: "OrderNew",
                orderPluralized: "Uppdrag",
                calls: "Samtal",
                callsPluralized: "Samtal",
                notes: "Anteckning",
                notesPluralized: "Anteckningar",
                guests: "Anmälning",
                guestsPluralized: "Anmälningar",
                documents: "Dokument",
                documentsPluralized: "Dokument",
                memorial: "Minnessida",
                memorialPluralized: "Minnessida",
                log: "Logg",
                logPluralized: "Loggar",
                message: "Meddelande",
                messagePluralized: "Meddelanden",
                probate: "Bouppteckning",
                probatePluralized: "Bouppteckning",
                party: "Part",
                partyPluralized: "Parter",
            },
            messages: {
                notFound: "Hittade inte meddelande",
                addMessage: "Skicka",
                updateMessage: "Ändra",
                deletePopupTitle: "Meddelande kommer tas bort",
                deletePopupContent: "Är du säker på att du vill ta bort meddelandet?",
                messagePlaceholder: "Anteckning...",
                messageTooltip: "Visa meddelandelista",
                refreshMessageList: "Uppdatera meddelandelista",
                messageEditedText: "Ändrat",
                shortcutAddMessage: "Lägg till meddelande med Ctrl+Enter",
                sendButtonEnable: "Uppdraget måste vara en order och kunden aktiverad för att kunna skicka meddelande"
            },
            probates: {
                probate: "Bouppteckning",
                createNewProbateCase: {
                    buttonText: "Skapa ett nytt bouppteckningsärende",
                    popupInfo: {
                        createProbateCase: "Skapa bouppteckningsärende",
                        createCaseHeaderInfo: "Du skapar ett nytt bouppteckningsärende, där information om den avlidne samt alla kontakter kopieras från det här ärendet till det nya bouppteckningsärendet. Vill du fortsätta?"
                    }
                },
                connectAnExistingCase: {
                    buttonText: "Koppla ett befintligt {GENERIC}särende",
                    popupInfo: {
                        chooseFromExistingProbateCase: "Koppla ett befintligt {GENERIC}särende",
                        probateCases: "Sök efter befintliga bouppteckningsärenden nedan. Välj det bouppteckningsärende du vill koppla till begravningsärendet, och klicka på \"Koppla\"",
                        funeralCases: "Sök efter befintliga begravningsärenden nedan. Välj det begravningssärende du vill koppla till bouppteckningsärendet, och klicka på \"Koppla\"",
                        funeral: "Begravning",
                        connect: "Koppla"
                    }
                },
                checkTiming: "Bouppteckningsinformation",
                checksEstate: "[Probate Checks / Estate administration]",
                beneficiaries: "Dödsbodelägare och efterarvingar",
                information: "Upplysningar",
                notes: "Anteckningar",
                assetsDebts: "Tillgångar/skulder",
                assetsDebtsDetails: {
                    splitItem: "Dela",
                    splitItemToolTip: "Dela {typeName} med {propertyOwner}",
                    svenskFast: {
                        abbreviation: "SF",
                        sendButton: "Skicka till Svensk Fast",
                        toolTip: "Skicka till Svensk Fast",
                        popup: {
                            seller: "Säljare",
                            objectType: "Objektstyp",
                            streetNumber: "Nummer",
                            streetDoor: "Port/uppgång/lägenhet",
                            message: "Meddelande till mäklare",
                            streetHelper: "Ex. Storgatan (utan nummer)",
                            doorHelper: "Ex. A, Lgh 101",
                            postalCodeHelper: "Ex. 59395 (enbart siffror)",
                            object: "Objekt",
                        },
                        successfullySent: "Skickat till Svensk Fast",
                        createdDate: "Skickad datum",
                        identity: "Säljare",
                        objectType: "Objektstyp",
                        address: "Adress",
                        message: "Meddelande till mäklare",
                        externalLeadId: "Unikt id-nummer",
                    },
                },
                probateMeeting: "Förrättning",
                meetingInfo: {
                    manualDueDateMustHaveDocument: "Om ett manuellt förrättningsdatum sätts måste ett dokument laddas upp"
                },
                probateChecks: "[Probate Checks]",
                meeting: "Möte",
                dateTimeOfProbateMeeting: "Datum/tid för förrättning",
                widowOrWidower: "Änka/änkling",
                maritalStatus: "Civilstatus",
                place: "Plats",
                dueDate: "Förfallodatum",
                owner: "Ägare",
                reviewedAndApproved: "Granskat och godkänt",
                reviewedDateAndTime: "Datum/tid för godkännande",
                reviewer: "Granskare",
                wantsHelpWithEstateAdministration: "[Wants help with estate administration]",
                questionnaire: {
                    probateQuestionnaire: "Frågeformulär",
                    open: "Frågeformulär",
                    confirmBtnTitle: "Spara",
                    addNewPerson: "Lägg till kontakt",
                    add: "Lägg till",
                    delete: "Ta bort",
                    beneficiaryHeaderPlaceholder: "Arvtagare i testamente",
                    beneficiaryHeaderEmptyPlaceholder: "",
                },
                deleteBeneficiaryPopup: {
                    header: "Dödsbodelägare kommer tas bort",
                    content: "Är du säker på att du vill ta bort '{name}'?"
                },
                updated: "Uppdaterad",
                datePlaceholder: "Inget datum",
                beneficiaryAlreadyExists: "Dödsbodelägare är redan tillagd",
                headerTitle: {
                    name: "Name",
                    applicant: "Bouppgivare",
                    legalStatus: "Legal status",
                    attendEvent: "Närvarade vid förrättning",
                    wantToParticipateInventory: "Vill närvara vid förrättning",
                    calledToEvent: "Kallad till förrättning",
                    confirmedCalledOfEvent: "Kallelsebevis mottaget",
                    renounceInheritance: "avsagt sig arv",
                    isEstateExecutor: "Är testamentesexekutor",
                    deceasedDetails: "Den avlidne",
                    spouseDetails: "Den efterlevande",
                    notes: "Det antecknas att:"
                },
                commonNote: {
                    existingNotes: "Vanliga anteckningar",
                    selectNote: "Vanliga anteckningar",
                    selectBtn: "Lägg till"
                },
                options: {
                    yes: "Ja",
                    yesViaRepresentative: "Ja via ombud",
                    no: "Nej",
                    beneficiaries: "Dödsbodelägare",
                    successor: "Efterarvinge",
                },
                isDeceased: "Avliden",
                selectOrUploadDocument: "Välj eller ladda upp dokument",
                selectDocument: "Välj dokument",
                assetsDebtsInvalidText: "Fyll i all information på fliken Tillgångar/skulder",
                assetsDebtsPopup: {
                    title: "{popupTitle} kommer tas bort",
                    body: "Är du säker på att du vill ta bort {CONTENT}?",
                    ofValue: "av värde",
                },
                case: "Ärende",
                type: "Typ",
                value: "Värde",
                appendix: "Bilaga",
                name: "Beskrivning",
                getOrderTotal: "Hämta summa från uppdrag",
                assets: "Tillgångar",
                debts: "Skulder",
                deductions: "Avdrag",
                net: "Netto",
                deceased: "Den avlidne",
                matrimonialProperty: "Giftorättsgods",
                privateProperty: "Enskild egendom",
                estateHolding: "Boets behållning",
                spouse: "Den efterlevande",
                summary: "Sammanställning",
                resetHelpMsg: "Förfallodatum kommer räknas ut, när bouppteckningen sparas",
                downloadAssetsAndDebts: "Ladda ner tillgångar/skulder"
            },
            logs: {
                eventLogs: "Logg",
                workLogs: "Tidslogg",
                user: "Användare",
                hours: "Timmar",
                hoursInputUnit: " (ex 0.1)",
                start: "Start",
                end: "Slut",
                order: "Uppdrag",
                workType: "Arbetstyp",
                note: "Anteckning",
                includedInInvoice: "Fakturerbar",
                deleteMessage: "Är du säker att du vill ta bort tidsloggen?",
                toasterMessageSaved: "Tidslogg sparad",
                toasterMessageDeleted: "Tidslogg borttagen",
                toasterMessageEdited: "Tidslogg uppdaterad",
                logWork: "Logga tid",
                date: "Datum",
                orders: "Uppdrag",
                amountOfTime: "Tid",
                description: "Anteckning",
                filter: {
                    fromDate: "Från datum",
                    toDate: "Till datum"
                },
                sum: "Summa",
                totalSummaryOfTimeSpent: "Total tid",
                totalSummaryOfTimeSpentIncludedInInvoice: "Fakturerbar tid"
            },
            workLog: {
                downloading: "Laddar ner tidslogg-PDF",
                downloadedSuccessful: "Tidslogg-PDF nerladdad",
                popup: {
                    title: "Välj tidsloggar och kolumner",
                    allRows: "Alla",
                }
            },
            parties: {
                conflicted: "Jävskonflikt",
                listType: {
                    client: "Klient",
                    lawyer: "Klients jurist",
                    counterClient: "Motpart",
                    counterLawyer: "Motparts jurist",
                    unknownParty: "Okänd part"
                },
                readOnlyWarning: "Dessa fält kan ej ändras här. Ändra på fliken \"Kontakter\".",
                addNewParty: "Ny part",
                deletePartyPopUpTitle: "Är du säker på att du vill ta bort \"CONTACT_NAME\"?",
                partyUpdated: "Part uppdaterad",
                conflictOfInterestPopup: {
                    confirmBtn: "Ja, spara ärendet",
                    cancelBtn: "Nej, jag behöver kolla med min chef"
                },
                recordAlreadyExists: "[Record already exists]",
                organisationNo: "Organisationsnummer",
                personNo: "Personnummer"
            }
        },
        orders: {
            orders: "Uppdrag",
            specificationTab: "Specifikation",
            footerForOrder: {
                saveOrder: "Spara",
                deleteOrder: "Ta bort"
            },
            defaultOrderTypeFilter: {
                funeral: "Begravning",
                legal: "Juridik"
            },
            allOrderTypes: "Alla uppdragstyper",
            tabs: {
                specification: "Specifikation",
                notes: "Anteckningar",
                log: "Logg",
                notesPluralized: "Notes",
                logPluralized: "Logs"
            },
            invoice: {
                invoiceDate: "Fakturadatum",
                dueDate: "Förfallodatum",
                invoiceHelpText: "Sätts då fakturan skickas.",
                invoiceSentBy: "Fakturan har skickats",
                pauseInvoiceTitle: "Pausa fakturan (max 10 dagar)",
                pauseInvoice: "Pausa fakturan",
                unPauseInvoice: "Avpausa fakturan",
                invoicePauseUntil: "Fakturan pausad till",
                invoiceNotPaused: "Fakturan är inte pausad",
                billing: "Fakturering",
                custom: "Anpassad (max 10 dagar)",
                days: "dagar",
                notInvoicedYet: "Ej fakturerat",
                notSetYet: "Ej definierat"
            },
            disbursementPaymentOptions: {
                customer: "Kund",
                funeralPartnerByCheque: "Begravningspartner med check",
                funeralPartnerOther: "Begravningspartner Annat",
                invoicedToFenix: "Faktureras till Fenix",
                fenixByPhone: "Fenix via telefon",
                fDChequeAtFuneral: "[FD cheque at funeral]"
            },
            disbursementPayment: "DisbursementPayment",
            confirmed: "Bekräftat",
            notConfirmed: "Inte bekräftat",
            sumExVat: "Summa ex. moms",
            vat: "Moms",
            sum: "Summa",
            compensation: "Ersättning",
            tags: {
                pausedUntil: "Pausad tills"
            },
            funeralPopupPackage: {
                funeralLabel: "Begravning",
                cremation: "Kremering",
                ceremony: "Ceremoni",
                ceremonyOutsideHomeCongregation: "Utanför hemförsamling",
                entombmentOutsideHomeCongregation: "Gravsättning utanför hemförsamling",
                personalFarewell: "Personligt avsked",
                personalMeeting: "Personligt möte",
                yes: "Ja",
                no: "Nej",
                cantDecide: "Vet ej",
                upgrade: "Uppgradera paket"
            }
        },
        calls: {
            caller: "(uppringare)",
            calls: "Samtal",
            unknownCallLabel: "(Okänt)",
            callTabContent: {
                recording: "Inspelning",
                location: "Plats",
                phone: "Telefon",
                callerId: "Uppringare",
                case: "Ärende",
                source: "Källa",
                callCreated: "Samtal skapat",
                callTakenBy: "Besvarat av",
                callVisitorData: "Besöksdata"
            },
            notesTabContent: {
                addNote: "Lägg till",
                reset: "Nollställ"
            },
            logTabContent: {
                dateTime: "Tidsstämpel",
                event: "Händelse",
                description: "Beskrivning",
                user: "Användare"
            },
            footer: {
                save: "Spara",
                delete: "Ta bort"
            },
            tabs: {
                call: "Samtal",
                notes: "Anteckningar",
                log: "Logg",
                notesPluralized: "Notes",
                logPluralized: "Logs"
            }
        },
        contacts: {
            contacts: "Kontakt",
            detailsTabContent: {
                contactName: "Namn",
                phone: "Telefon",
                email: "E-post",
                address: "Besöksadress",
                postalCode: "Postnummer",
                city: "Postort",
                country: "Land",
                mailingAddress: {
                    mailingAddress: "Postadress",
                    address: "Adress",
                    postalCode: "Postnummer",
                    city: "Postort"
                },
                type: "Typ",
                picture: "Bild",
                comments: "Kommentar"
            },
            footer: {
                save: "Spara",
                delete: "Ta bort"
            },
            tabs: {
                details: "Detaljer",
                map: "Karta",
                locations: "Platser"
            }
        },
        help: {
            sectionTitle: "Hjälp",
            getHelp: "Rapportera bugg/få hjälp/föreslå ny funktion",
            idaKnowledgeBase: "IDA Knowledge Base"
        },
        settings: {
            settings: "Inställningar",
            organisation: {
                organisation: "Organisation",
                contact_email: "E-post kontaktperson",
                update_logo: "Uppdatera logotyp",
                atleast_96_px_wide: "Minst 96px bred i JPG- eller PNG-format.",
                save_organisation: "Spara"
            },
            users: {
                users: "Användare",
                add_user: "Lägg till användare",
                defaultMaxDistance: "Standard max reseavstånd",
                picture: "Bild",
                email: "E-post",
                externalEmail: "Extern e-post",
                initials: "Initialer",
                name: "Namn",
                culture: "Språk",
                add_user_form: {
                    add_user: "Lägg till användare",
                    edit_user: "Redigera användare",
                    email: "E-post",
                    externalEmail: "Extern e-post",
                    initials: "Initialer",
                    name: "Namn",
                    set_culture: "Ställ in språk",
                    select_culture: "Välj språk",
                    two_characters_displayed: "2 bokstäver, används vid förkortningar",
                    phone_number: "Telefonnummer",
                    enable_user: "Aktivera användare",
                    roles: "Roller",
                    update_user: "Spara"
                }
            },
            productCategory: {
                productCategory: "Produktkategorier",
                add_product_category: "Lägg till produktkategori",
                name: "Namn",
                slug: "Slug",
                description: "Beskrivning",
                product_category_view: "Sida produktkategori",
                product_category_name: "Namn produktkategori",
                product_category_form: {
                    add_product_category: "Lägg till produktkategori",
                    edit_product_category: "Redigera produktkategori",
                    product_category_name: "Namn produktkategori",
                    description: "Beskrivning",
                    slug: "Slug",
                    select_product_category_name: "Överordnad kategori",
                    select_parent_product_category_name: "Välj överordnad kategori (valfritt)",
                    enable_category: "Aktivera produktkategori",
                    sections: "Sektioner",
                    eventTypes: "Eventtillhörighet",
                    update_product_category: "Spara",
                    showInFunnel: "Visa på publika webbens kategorilista"
                },
                grossMargin: "Standardbruttomarginal",
                commission: "Standardprovision"
            },
            product: {
                product: "Produkt",
                add_product: "Lägg till produkt",
                product_category_name: "Produktkategorinamn",
                name: "Namn",
                id: "Id",
                type: "Typ",
                typeId: "TypId",
                description: "Beskrivning",
                price: "Pris",
                product_detail_view: "Produktdetaljer",
                product_name: "Produktnamn",
                max_order_amount: "Max antal",
                product_category_details: "Produktkategori - detaljer",
                category_name: "Kategorinamn",
                product_variants_details: "Produktvariant - detaljer",
                product_child_details: "Produktbarn - detaljer",
                add_product_form: {
                    add_product_variants: "Lägg till variant",
                    add_child: "Lägg till barn",
                    product_name: "Produktnamn",
                    category_name: "Kategorinamn",
                    select_product_category_name: "Välj kategorinamn",
                    please_select_category: "Välj kategori"
                },
                productGrossMargin: "Bruttomarginal",
                productCommission: "Provision",
                productStandardMargin: "Använd standardbruttomarginal",
                productStandardCommission: "Använd standardprovision"
            },
            location: "Platser",
            export: "Exportera",
            token: "API-nyckel",
            copyToken: "Kopiera nyckel",
            sendNotification: "Skicka meddelande till alla användare",
            sendNotificationButton: "Skicka",
            sendNotificationPlaceholder: "Skriv meddelande..."
        },
        myAccount: {
            myAccount: "Mitt konto",
            profile: {
                profile: "Profil",
                email: "E-post",
                externalEmail: "Extern e-post",
                name: "Namn",
                initials: "Initialer",
                phone_number: "Telefonnummer",
                two_characters: "2 bokstäver, används vid förkortningar.",
                go_to: "Gå till ",
                security: "Säkerhet",
                to_change_your_password: " för att byta ditt lösenord.",
                square_jpg_png: "Kvadratisk JPG- eller PNG-fil, minst 96x96px.",
                save_profile: "Spara",
                update_profile_picture: "Uppdatera profilbild",
                update_picture: "Uppdatera profilbild",
                vendor: "Leverantör (BC)",
            },
            public: {
                profile: "Publik profil",
                publicProfileWarning: "Din publika profil visas för kunder på",
                firstName: "Förnamn",
                lastName: "Efternamn",
                address: "Adress",
                postalCode: "Postnummer",
                city: "Postort",
                religions: "Religion/ceremoniordning",
                religionsGeneral: "Alla religioner/ceremoniordningar",
                allReligionsHelp: "Lämna ikryssad om alla typer är möjliga.",
                religionsHelp: "Ange religioner och ceremoniordningar du kan jobba med.",
                languages: "Språk",
                singularLanguages: "språk",
                languagesPlaceholder: "Svenska, Engelska",
                languagesHelp: "Ange språk du talar flytande.",
                bio: "Om mig",
                bioHelp: "Skriv en kort text om din bakgrund (ca 80-120 ord) till potentiella kunder. Det viktigaste är att visa medmänsklighet och skapa förtroende.",
                updateProfilePic: "Uppdatera publik profilbild",
                profilePicRequirements: "Kvadratisk JPG- eller PNG-fil, måste vara exakt 800x800px.",
                savePublicProfile: "Spara publik profil",
                savePublicProfileWarning: "När du sparar uppdateras fenixbegravning.se direkt",
                tips: "Tips",
                tipsHelp: "Skriv några hjälpsamma tips från hjärtat till en sörjande person.",
                tipsEdit: "Redigera tips",
                tipPlaceholder: "Tips {COUNT}",
                description: "Beskrivning",
                questions: "Frågor",
                questionsHelp: "Skriv några vanliga frågor du får och svaren till dem.",
                questionsEdit: "Redigera frågor & svar",
                questionPlaceholder: "Fråga {COUNT}",
                answer: "Svar",
                admin: "Admin",
                showProfile: "Visa profilen på publika webben",
                addressHelp: "Din adress visas inte publikt, den används endast för att beräkna hur nära du är till kunden.",
                latitude: "Latitud",
                longitude: "Longitud",
                latitudeAndLongitude: "Latitud, longitud",
                maxTravelDistance: "Max reseavstånd",
                maxTravelDistanceHelp: "Maximalt avstånd du kan resa från din adress för att besöka en kund.",
                savedSuccessfully: "Publik profil sparad"
            },
            security: {
                security: "Säkerhet",
                change_password: "Byt lösenord",
                new_password: "Nytt lösenord",
                new_password_again: "Repetera nytt lösenord",
                current_password: "Nuvarande lösenord",
                enter_your_current_password: "Ange ditt nuvarande lösenord för att godkänna ändringar.",
                update_password: "Spara"
            },
            notifications: {
                notifications: "Notiser",
                notifications_empty: "Inga nya meddelanden",
                notifications_empty_message: "Du har läst allt!",
                notifications_empty_message_2: "Snyggt jobbat. En snabb fikapaus?",
                notifications_empty_message_3: "Vilken pärla du är! Wow!",
                notifications_empty_message_4: "Dags att stretcha lite och titta bort från skärmen?",
                notifications_empty_message_5: "Du ser strålande ut idag. Har du gjort något nytt med håret?",
                older: "Äldre",
                mark_all_as_read: "Markera allt som läst",
                cases: {
                    cases: "Ärenden",
                    allNewCases: "Alla nya ärenden",
                    newCaseFromCall: "Nytt ärende från samtal",
                    newCaseFromWeb: "Nytt ärende från webben",
                    caseAssignedToMe: "Ärende tilldelat mig",
                    caseStatusUpdated: "Ärendestatus uppdaterad",
                    caseLabelsAddedRemoved: "Ändrat etikett på ärende",
                    caseNoteAdded: "Ny anteckning på ärende"
                },
                orders: {
                    orders: "Uppdrag",
                    newOrderAddedToMyCase: "Nytt uppdrag i mitt ärende",
                    orderStatusUpdated: "Uppdragsstatus uppdaterad",
                    orderLabelsAddedRemoved: "Uppdragsetiketter ändrade",
                    orderNoteAdded: "Ny anteckning på uppdrag"
                },
                save_notifications: "Spara"
            },
            settings: {
                settings: "Inställningar",
                role: "Roll",
                roles: "Roller",
                contact_your_administrator: "Kontakta admin för att ändra din roll.",
                automatic_log_out: "Automatisk utloggning",
                automatic_log_out_after_a_period_of_inactivity: "Logga ut automatiskt efter en period av inaktivitet.",
                save_settings: "Spara",
                darkMode: "Mörkt tema",
                enableDarkMode: "Aktivera mörkt tema",
                saveTheEnvoiroment: "Spara både energi och dina ögon",
                locale: "Språk (language)"
            },
            compensation: {
                compensation: "Ersättning",
                month: "Månadsöversikt",
                sendSummary: "Skicka"
            }
        },
        search: {
            placeholder: "Sök...",
            map: "Karta",
            enterToSearch: "Tryck Enter för att söka",
            clearSearch: "Rensa sökning",
            caseSingular: "Ärende",
            casePlural: "Ärenden",
            orderSingular: "Uppdrag",
            orderPlural: "Uppdrag",
            callSingular: "Samtal",
            callPlural: "Samtal",
            productSingular: "Produkt",
            productPlural: "Produkter",
            contactSingular: "Kontakt",
            contactPlural: "Kontakter",
            customerSingular: "Kund",
            locationSingular: "Plats",
            locationPlural: "Platser",
            mapLegendCeremony: "Ceremoniplats",
            mapLegendGraveyard: "Kyrkogård",
            mapLegendParish: "Församlingshem",
            mapLegendHospital: "Sjukhus",
            all: "Alla"
        },
        common: {
            save: "Spara",
            create: "Skapa",
            cancel: "Avbryt",
            done: "Ok",
            expandAll: "Expandera alla",
            collapseAll: "Kollapsa alla",
            dropDownOptionPleaseSelect: "Välj...",
            dropDownOptionTypeToSearch: "Skriv för att söka",
            dropDownOptionLoadingMessage: "Söker...",
            dropDownCreateMessage: "Skapa ",
            noOptionMessage: "Inga alternativ",
            dropfilehere: "Släpp fil här för att ladda upp",
            applyFilter: "Filtrera",
            resetFilter: "Återställ",
            allColumnReset: "Återställ alla listor",
            textIs: "Fritextsök:",
            ownerIs: "Ägare:",
            unknown: "Okänd",
            labelIs: "Etikett:",
            sortingIs: "Sortering:",
            noOwner: "Ingen ägare",
            edit: "Redigera",
            view: "Se detaljer",
            delete: "Ta bort",
            reset: "Återställ",
            type: "Typ",
            locked: "Låst",
            removeLabel: "Ta bort",
            pickColor: "Välj färg",
            emailAddress: "E-postadress",
            loading: "Laddar...",
            copyText: "Kopiera text",
            greeting: "Text",
            writeGreeting: "Skriv text...",
            writePlaceholder: "Skriv...",
            instructions: "Instruktioner för ceremonin",
            writeInstructions: "Skriv instruktioner för ceremonin...",
            instructionsHelpText: "Text som skrivs här används i ärendets instruktionsdokument.",
            yesDelete: "Ja, ta bort",
            noGoBack: "Nej, gå tillbaka",
            version: "Version",
            emailSend: "Skicka",
            emailResend: "Skicka igen",
            emailSendInvitation: "Skicka inbjudan",
            emailSendInvitationAgain: "Skicka inbjudan igen",
            avatar: "Profilbild",
            myAvatar: "Min profilbild",
            easterEgg: "Easter egg",
            close: "Stäng",
            closePanel: "Stäng panel",
            link: "Länk",
            changeStagePopup: {
                changeStage: "Ändra status",
                infoCaseOrderInvoice: "Ärendet måste ha en ägare för att för att ärende ska kunna faktureras",
                info: "Ärendet måste ha en ägare för att markeras som ",
                infoSalesExternal: "Extern försäljare får inte tilldela ärende till sig själv ",
                infoOrder: "Uppdraget måste ha en faktureringskontakt för att status ska kunna ändras",
                infoCaseOrder1: "Minst ett uppdrag behöver vara en order",
                infoCaseOrder2: "Minst ett uppdrag måste markeras som Faktura",
                infoCaseOrder3: "Alla uppdrag måste markeras som Arkiv eller Borttagen",
                infoCaseOrder4: "Alla uppdrag måste markeras som Faktura, Arkiv eller Borttagen",
                infoCaseOrder5: "Alla uppdrag måste markeras som Borttagen",
                owner: "Ägare",
                ownerPlaceholder: "Välj ägare",
                billingContact: "Faktureringskontakt",
                selectDeliveryMethod: "Leveransmetod",
                deliveryMessage: "En faktura skickas till kunden.",
                toShowDeliveryMethodsContactMandatoryInfo: "Fakturamottagare måste ha förnamn, efternamn, adress, postnummer och postort för att kunna skicka faktura. Endast boende i Storbritannien kan betala med Paypal.",
                creditMessage: "En kreditfaktura skickas till kunden.",
                cannotMoveToStage: "Kan inte flytta till denna status",
                cannotMoveToStageWithoutOrders: "Kan inte flytta till denna status - finns inget uppdrag på ärendet",
                notAuthorized: "Ej auktoriserad",
                lossReason: "Tapporsak",
                cause: "Orsak",
                changeStageBtn: "Ändra status",
                changeStageBtnAndEmail: "Ändra status & skicka e-post",
                cancelBtn: "Avbryt",
                error: {
                    selectOwner: "Vänligen välj ägare",
                    lossReason: "Vänligen välj tapporsak",
                    deliveryMethod: "Välj leveransmetod",
                    selectCounty: "Välj län"
                },
                includeWorkLogSpecification: "Inkludera tidsloggs-PDF i faktura-e-post",
            },
            escKeyboardShortcut: {
                text: "ESC",
                title: "Stäng panel (ESC)"
            },
            showNewItems: "Visa nya objekt",
            loadMoreBtn: "Hämta fler",
            noData: "Slut",
            nothingToDisplay: "Inget innehåll",
            createdBy: "av",
            createdBySystem: "av IDA",
            reload: "Ladda om",
            add: "Lägg till",
            remove: "Ta bort",
            filter: "Filtrera",
            searchAndFilter: "Sök/filtrera",
            emailSummary: "E-posta sammanställning",
            emailOnSaveCaseChanges: {
                title: "Kund behöver informeras",
                description: "Du har gjort ändringar i ett eller flera uppdrag. Välj att kommunicera ändringarna till kund."
            },
            sendEmail: "Skicka e-post",
            caseNotSaved: "Ärendet ej sparat",
            orderNotSaved: "Uppdraget ej sparat",
            dontSend: "Skicka ej",
            emailTemplate: "E-postmall",
            summary: "Sammanställning",
            guestList: "Gästlista",
            documents: "Dokument",
            caseEmail: {
                summary: "Sammanställning",
                guestList: "Gästlista",
                documents: "Dokument"
            },
            orderEmail: {
                summary: "Sammanställning",
                proposal: "Offertsammanfattning",
                proposalUpdated: "Offertuppdatering",
                confirmed: "Orderbekräftelse",
                orderUpdated: "Orderuppdatering",
                invoiceCreated: "Fakturasammanfattning",
                manualSignup: "Kundkonto-inbjudan"
            },
            existingContact: "Befintlig kontakt",
            custom: "Anpassad",
            recipient: "Mottagare",
            message: "Meddelande",
            messageOptional: "Meddelande (valfritt)",
            subject: "Ämne",
            microsoftNavId: "Microsoft BC-ID",
            customerAccountId: "IDA kundnummer",
            mapLocation: "Nuvarande plats",
            uploadPicture: "Ladda upp bild",
            automaticallySaved: "Sparas automatiskt",
            play: "Spela upp",
            pause: "Pausa uppspelning",
            speed100: "100% uppspelningshastighet",
            speed125: "125% uppspelningshastighet",
            speed150: "150% uppspelningshastighet",
            unit: {
                pieces: "st",
                kronor: "kr",
                gbp: "£",
                people: "pers",
                km: "km",
                percent: "%",
                sek: "SEK"
            },
            unitFull: {
                pieces: "Antal",
                kronor: "Pris i kronor",
                gbp: "Pris i £",
                people: "Antal personer",
                km: "Kilometer",
                percent: "Procent"
            },
            objectType: {
                case: "Ärende",
                order: "Uppdrag",
                call: "Samtal",
                product: "Produkt",
                location: "Plats",
                contact: "Kontakt",
                todo: "Att göra",
                document: "Dokument"
            },
            sending: "Skickar...",
            nps: {
                title: "Kundnöjdhet (NPS)",
                class: "Kategori",
                score: "Betyg",
                comment: "Kommentar",
                contact: "Kontakt",
                classPromoter: "Ambassadör",
                classPassive: "Passiv",
                classDetractor: "Kritiker",
                classUnknown: "Okänt",
                commentEmpty: "Ingen kommentar",
                requestSent: "Undersökning skickad"
            },
            document: {
                document: "Dokument",
                documents: "Dokument",
                order: "Order",
                created: "Skapad",
                status: "Status",
                visibleToCustomer: "Tillgänglig för kund",
                documentUpdated: "Dokument uppdaterat",
                emailSelected: "Skicka markerade",
                email: "Skicka",
                cancel: "Avbryt",
                save: "Spara",
                documentType: "Dokumenttyp",
                fileGenerateDocument: "Skapa dokument",
                fileUploadDocument: "Ladda upp dokument",
                fileUploadDocumentOr: "eller",
                fileDragDropToUpload: "Dra och släpp fil här för att ladda upp",
                fileTypeAllowed: "Tillåts: pdf, jpg, jpeg, png, gif, bmp",
                download: "Ladda ner",
                edit: "Redigera",
                newVersion: "Ny version",
                viewLog: "Visa logg",
                delete: "Ta bort",
                categorySubCategoryDocumentTypeNotFound: "Inte hittat: Kategori> Underkategori> Dokumenttyp",
                chooseFile: "Välj fil",
                rejectedFile: "Filen kan inte laddas upp",
                dropReleaseHelpText: "Släpp för ladda upp filen",
                pageXOfXPage: "Sida",
                pageXOfXOf: "av",
                loader: {
                    docGeneration: "Skapar dokument...",
                    docGenerationSuccess: "Dokument skapat",
                    docFetching: "Hämtar dokument...",
                    docFetchingFailure: "Kan inte visa filen, okänd filtyp",
                    docLoading: "Laddar dokument...",
                    generatedDocSavedSuccess: "Sparad",
                    docUploading: "Laddar upp dokument...",
                    docUploadingSuccess: "Uppladdad",
                    docDeleting: "Tar bort dokument...",
                    docPreview: "Förhandsgranskning dokument"
                },
                selectOrderDropDown: "Välj uppdrag",
                optional: "valfritt",
                documentLog: "Logg",
                orderRequiredForFurtherAction: "Lägg till ett uppdrag för att fortsätta",
                downloadDocx: "Ladda ner .docx",
                includeAttachments: "Inkludera bilagor",
                probateDocumentUploadWarning: "Bouppteckning kan endast laddas upp som PDF"
            },
            timestamp: "Tidsstämpel",
            and: "och",
            todo: {
                save: "Spara",
                cancel: "Avbryt",
                remove: "Ta bort",
                edit: "Redigera",
                description: "Beskrivning",
                descriptionPlaceholder: "Skriv för att lägga till eller välj mall...",
                descriptionCreatePlaceholder: "Skriv för att lägga till todo",
                deadline: "Deadline",
                deadlinePlaceholder: "Inget datum",
                timeStamp: "Markerad klar",
                showAll: "Visa alla",
                showLatestThree: "Visa tre senaste",
                showDetails: "Visa detaljer",
                hideDetails: "Dölj detaljer",
                status: "Status",
                statusNormal: "Normal",
                statusDueSoon: "Inom kort",
                statusDue: "Idag",
                statusOverDue: "Förfallen",
                statusDone: "Klar",
                selectedTemplateWarning: "Du har valt en mall som redan är tillagd - vill du fortsätta?"
            },
            loadingLog: "Hämtar logg...",
            somethingWentWrong: "Någonting gick fel",
            logWork: "Logga tid",
            updateProfile: "Kund uppdaterad",
            errorBoundary: {
                title: "Ett fel har inträffat. Ladda om sidan genom att klicka på knappen nedan. Om felet kvarstår, testa att logga ut och logga in igen.",
                button: "Ladda om sidan"
            },
            atLeast1Validation: "Minst ett {FIELD_NAME} krävs",
        },
        lockPage: {
            banner: {
                messages: {
                    reloadToSeeLatestData: "Ladda om för att se de senaste ändringarna",
                    dataOutdated: "Ärendet har blivit uppdaterat",
                    currentlyBeingEditedBy: "Detta ärende redigeras just nu av"
                },
                buttons: {
                    takeOver: "Ta över redigering",
                    takeOverToolTip: "Ta över redigering från den som redigerar ärendet just nu",
                    reload: "Ladda om",
                    reloadToolTip: "Hämta senaste ändringarna"
                }
            },
            accessType: {
                owner: "ägare",
                viewing: "tittar",
                editing: "redigerar"
            },
            takeOverPopup: {
                header: "Den som redigerar ärendet kan förlora ändringar",
                content: "redigerar detta ärende just nu och kan förlora sina ändringar. Är du säker att du vill ta över redigeringen?",
                okBtn: "Ja, ta över",
                editor: "Redigerar"
            }
        },
        staticData: {
            stages: {
                order: {
                    all: {
                        text: "Alla"
                    }
                },
                orderType: {
                    funeralWithCeremony: {
                        textShort: "Med ceremoni",
                    },
                    funeralWithoutCeremony: {
                        textShort: "Utan ceremoni"
                    },
                },
                eventType: {
                    all: {
                        text: "Alla",
                        description: "Alla beskrivningar"
                    },
                    ceremony: {
                        text: "Ceremoni",
                        description: "Ceremoni"
                    },
                    entombment: {
                        text: "Gravsättning",
                        description: "Gravsättning"
                    },
                    reception: {
                        text: "Minnesstund",
                        description: "Minnesstund"
                    },
                    preparation: {
                        text: "Förberedelser",
                        description: "Förberedelser"
                    }
                }
            },
            contacts: {
                personType: {
                    all: {
                        text: "Alla",
                        description: "Alla beskrivningar"
                    },
                    admin: {
                        text: "Admin",
                        description: "Admin beskrivning"
                    },
                    ambassador: {
                        text: "Ambassadör",
                        description: "Ambassadör beskrivning"
                    },
                    agent: {
                        text: "Ombud",
                        description: "Ombud beskrivning"
                    }
                }
            },
            productTypes: {
                normalProduct: {
                    text: "Normalprodukt",
                    description: "En produkt som säljs som dem är utan varianter eller barn"
                },
                productWithVariants: {
                    text: "Variantprodukt",
                    description: "En produkt som inte kan säljas med varianter som kan säljas"
                },
                variantProduct: {
                    text: "Varianter",
                    description: "En variant på en produkt som kan söljas och tillgör en variantprodukt"
                },
                parentProduct: {
                    text: "Förälderprodukt",
                    description: "En komplex produkt som kan säljas och innehåller barnprodukter"
                },
                childProduct: {
                    text: "Barnprodukt",
                    description: "En barnprodukt som bara kan säljs i en komplex förälder produkt"
                },
                customProduct: {
                    text: "Anpassad produkt",
                    description: "Anpassad produkt, säljs separat med ett anpassat namn och pris"
                }
            },
            locationTypes: {
                ceremonyLocation: {
                    text: "Ceremoniplats",
                    description: "Ceremoniplats så som kyrkor och kapell"
                },
                graveyard: {
                    text: "Begravningsplats",
                    description: "Kyrkogårdar och andra begravningsplatser"
                },
                parishHome: {
                    text: "Församlingshem",
                    description: "Församlingshem till minnesstunder"
                },
                hospital: {
                    text: "Sjukhus",
                    description: "Sjukhus som driver bårhus"
                },
                custom: {
                    text: "Anpassad",
                    description: "Anpassad plats"
                },
                crematorium: {
                    text: "Krematorium",
                    description: "Krematorium"
                },
                naturalBurialGround: {
                    text: "Naturkyrkogård",
                    description: "Naturkyrkogård"
                },
                funeralDirector: {
                    text: "Begravningsentreprenör",
                    description: "Begravningsentreprenör"
                },
                chapel: {
                    text: "Kapell",
                    description: "Kapell"
                },
                coldRoom: {
                    text: "Kylrum",
                    description: "Kylrum"
                },
                externalOffice: {
                    text: "Externt kontor",
                    description: "Externt kontor"
                }
            },
            filter: {
                add: "Filtrera lista",
                allColumnSearch: "Filtrera alla listor",
                freeText: {
                    text: "Sök efter...",
                    description: "Fritextsök",
                    tooltip: "Sök"
                },
                stage: {
                    text: "Status",
                    description: "Välj status att visa i kolumnen"
                },
                orderStage: {
                    text: "Uppdragsstatus",
                    description: "Välj uppdragsstatus att visa i kolumnen"
                },
                label: {
                    text: "Etikett",
                    description: "Välj etikett att visa i kolumnen"
                },
                type: {
                    text: "Typ",
                    description: "Välj typ att visa i kolumnen"
                },
                orderType: {
                    text: "Uppdragstyp",
                    description: "Välj uppdragstyp att visa i kolumnen"
                },
                productCategory: {
                    text: "Produktkategori",
                    description: "Välj produktkategori att visa i kolumnen"
                },
                productType: {
                    text: "Produkttyp",
                    description: "Välj produkttyp att visa i kolumnen"
                },
                withOrWithoutCase: {
                    text: "Med eller utan ärende",
                    description: "Välj samtal med eller utan ärende",
                    values: {
                        withCase: {
                            text: "Med ärende",
                            description: "Med ärende"
                        },
                        withoutCase: {
                            text: "Utan ärende",
                            description: "Utan ärende"
                        }
                    }
                },
                callTakenByUser: {
                    text: "Samtal mottogs av",
                    description: "Samtal mottagna av en specifik användare"
                },
                caseAssignedToUser: {
                    text: "Ägare",
                    description: "Välj ärende som ägs av en specifik användare"
                },
                locationPlace: {
                    text: "Plats län eller stad",
                    description: "Välj län eller stad att visa i kolumnen"
                },
                locationType: {
                    text: "Platstyp",
                    description: "Välj platstyp att visa i kolumnen"
                },
                personType: {
                    text: "Persontyp",
                    description: "Välj persontyp att visa i kolumen"
                },
                ashesInstruction: {
                    text: "[Ashes instruction]",
                    description: "[Select which Ashes instructions to show in the dashboard]"
                },
                fdPartner: {
                    text: "[FD partner]",
                    description: "[Select funeral partner to show in the dashboard]"
                }
            },
            sort: {
                idAsc: {
                    text: "Id, stigande",
                    description: "Sortera på id, stigande ordning"
                },
                idDesc: {
                    text: "Id, fallande",
                    description: "Sortera på id, fallande ordning"
                },
                typeAsc: {
                    text: "Typ, stigande",
                    description: "Sortera på typ, stigande ordning"
                },
                typeDesc: {
                    text: "Typ, fallande",
                    description: "Sortera på typ, fallande ordning"
                },
                dateAsc: {
                    text: "Datum, äldst först",
                    description: "Sortera på datum, äldst först"
                },
                dateDesc: {
                    text: "Datum, nyast först",
                    description: "Sortera på datum, nyast först"
                },
                deadlineAsc: {
                    text: "Deadline, äldst först",
                    description: "Sortera på deadline, äldst först"
                },
                deadlineDesc: {
                    text: "Deadline, nyast först",
                    description: "Sortera på deadline, nyast först"
                },
                deceasedNameAsc: {
                    text: "Avlidens namn, stigande",
                    description: "Sortera på avlidens namn, stigande ordning"
                },
                deceasedNameDesc: {
                    text: "Avlidens namn, fallande",
                    description: "Sortera på avlidens namn, fallande ordning"
                },
                productNameAsc: {
                    text: "Produktnamn, stigande",
                    description: "Sortera på produktnamn, stigande ordning"
                },
                productNameDesc: {
                    text: "Produktnamn, fallande",
                    description: "Sortera på produktnamn, fallande ordning"
                },
                productTypeAsc: {
                    text: "Produkttyp, stigande",
                    description: "Sortera på produkttyp, stigande ordning"
                },
                productTypeDesc: {
                    text: "Produkttyp, fallande",
                    description: "Sortera på produkttyp, fallande ordning"
                },
                productCategoryAsc: {
                    text: "Produktkategori, stigande",
                    description: "Sortera på produktkategori, stigande ordning"
                },
                productCategoryDesc: {
                    text: "Produktkategori, fallande",
                    description: "Sortera på produktkategori, fallande ordning"
                },
                locationNameAsc: {
                    text: "Platsnamn, stigande",
                    description: "Sortera på platsnamn, stigande ordning"
                },
                locationNameDesc: {
                    text: "Platsnamn, fallande",
                    description: "Sortera på platsnamn, fallande ordning"
                },
                contactNameAsc: {
                    text: "Kontaktnamn, stigande",
                    description: "Sortera på kontaktnamn, stigande ordning"
                },
                contactNameDesc: {
                    text: "Kontaktnamn, fallande",
                    description: "Sortera på kontaktnamn, fallande ordning"
                },
                customNameAsc: {
                    text: "{CUSTOM_NAME}, stigande",
                    description: "Sortera på {CUSTOM_NAME} stigande ordning"
                },
                customNameDesc: {
                    text: "{CUSTOM_NAME}, fallande",
                    description: "Sortera på {CUSTOM_NAME} fallande ordning"
                },
                partnerNameAsc: {
                    text: "{PARTNER_NAME}, stigande",
                    description: "Sortera på {PARTNER_NAME} stigande ordning"
                },
                partnerNameDesc: {
                    text: "{PARTNER_NAME}, fallande",
                    description: "Sortera på {PARTNER_NAME} fallande ordning"
                }
            },
            webSocketNotificationTypes: {
                newCaseFromCall: {
                    message: "Nytt ärende från samtal"
                },
                newCaseFromWeb: {
                    message: "Nytt ärende från webben"
                },
                caseAssignedUser: {
                    message: "Ärende tilldelat"
                },
                caseStageUpdate: {
                    message: "Ärendestatus uppdaterad"
                },
                caseLabelAdded: {
                    message: "Ny ärendeetikett"
                },
                caseLabelRemoved: {
                    message: "Etiketten är borttagen"
                },
                caseNoteAdded: {
                    message: "Ny ärendeanteckning"
                },
                orderAddedToCase: {
                    message: "Nytt uppdrag i ärende"
                },
                orderStageUpdated: {
                    message: "Uppdragsstatus uppdaterad"
                },
                orderLabelAdded: {
                    message: "Ny etikett på uppdrag"
                },
                orderLabelRemoved: {
                    message: "Etikett borttagen från uppdrag"
                },
                orderNoteAdded: {
                    message: "Ny anteckning på uppdrag"
                },
                newCaseFromIdaUi: {
                    message: "Nytt ärende från IDA"
                },
                adminMessageFromIdaUi: {
                    message: "Meddelande från admin"
                },
                npsResponseNotification: {
                    message: "NPS-svar"
                },
                overdueTodo: {
                    message: "Försenad todo"
                },
                orderConfirmedFromPublicWeb: {
                    message: "Offert bekräftad från publika webben"
                },
                orderUpdated: {
                    message: "Uppdrag uppdaterat"
                },
                callAddedToCase: {
                    message: "Samtal kopplades till ärende"
                },
                memorialMemoryReported: {
                    message: "Ett minne har flaggats"
                },
                customerMessage: {
                    message: "Nytt meddelande"
                },
                caseCollaborator: {
                    message: "Medarbetare uppdaterad"
                }
            }
        },
        successMessages: {
            success: "Klar",
            deleted: "Borttagen",
            passwordUpdated: "Lösenord uppdaterat. Logga in igen.",
            productUpdated: "Produkt uppdaterad",
            locationUpdated: "Plats uppdaterad",
            contactAdded: "Kontakt tillagd",
            contactUpdated: "Kontakt sparad",
            caseAdded: "Ärende tillagt",
            caseUpdated: "Ärende uppdaterat",
            callUpdated: "Samtal uppdaterat",
            notificationsSaved: "Notiser sparade",
            orderUpdated: "Uppdrag uppdaterat",
            downloadSuccessful: "Nedladdning klar"
        },
        infoMessages: {
            popup: {
                unsavedChanges: "Du har osparade ändringar. Är du säker på att du vill lämna?",
                deleteRelative: "Är du säker på att du vill radera kontakten",
                deleteCall: "Är du säker på att du vill radera samtalet",
                deleteCase: "Är du säker på att du vill radera ärendet",
                deleteOrder: "Är du säker på att du vill radera uppdraget",
                deleteContact: "Är du säker på att du vill radera kontakten",
                deleteLocation: "Är du säker på att du vill radera platsen",
                deleteProduct: "Är du säker på att du vill radera produkten",
                deleteProductCategory: "Är du säker på att du vill radera produktkategorin",
                deleteUser: "Är du säker på att du vill radera användaren",
                deleteDocument: "Är du säker på att du vill radera dokumentet",
                deleteDocumentWithName: "Är du säker på att du vill radera dokumentet {DOCUMENT_NAME}?",
                delete: "Är du säker på att du vill radera?",
                emailWillBeSent: "E-post kommer skickas",
                emailWillBeSentAreYouSure: "E-post kommer skickas, är du säker?",
                okBtn: "Ok",
                saveBtn: "Spara",
                saveAndEmailBtn: "Spara och skicka e-post",
                cancelBtn: "Avbryt",
                closeBtn: "Stäng",
                deleteGuest: "Är du säker på att du vill radera gästen",
                unsavedMessageChanges: "Du har osparade ändringar. Är du säker på att du vill lämna?",
                visibleToCustomer: {
                    title: "{SHARE_CONDITIONALLY} detta med kunden?",
                    content: "\"{DOCUMENT_NAME}\" skapad {CREATED_TEXT} {VISIBLE_CONDITIONALLY} för kunden",
                    noBtn: "Nej",
                    share: "Vill du dela",
                    unShare: "Vill du inte dela",
                    visible: "kommer visas",
                    notVisible: "kommer inte visas"
                },
            }
        },
        warningMessages: {
            call: {
                addNotes: "Skriv en anteckning"
            },
            case: {
                addPhoneNumber: "Ange telefonnummer",
                addGuestPhoneNumber: "Ange telefonnummer för gäst",
                addName: "Ange namn",
                addCorrectEmail: "Ange e-postadress",
                addCorrectEmailGuest: "Ange e-postadress för gäst",
                addCorrectPhone: "Ange telefonnummer",
                addCorrectPhoneGuest: "Ange telefonnummer för gäst",
                addGuestName: "Ange namn för gäst",
                addNotes: "Skriv en anteckning",
                selectRelative: "Välj en kontakt",
                noProductsForCategory: "Det finns inga produkter i den kategorin",
                addCustomName: "Lägg till ett namn på den anpassade produkten utan mellanrum före eller efter text",
                addFoodRestriction: "Tillagt särskilt kostbehov saknar innehåll",
                addNameWithNoSpaces: "Ange namn utan mellanslag före eller efter",
                addPersonNumberWithNoSpaces: "Ange personummer utan mellanslag före eller efter",
                addAddressWithNoSpaces: "Ange adressen utan mellanslag före eller efter",
                addCityWithNoSpaces: "Ange staden utan mellanslag före eller efter",
                addPostalCodeWithNoSpacesAndNumbersOnly: "Ange postnummer utan mellanslag före eller efter",
                onlyNumberAndNoSpaceError: "Ange endast siffrorna 0-9, inga mellanslag",
                noSpaceError: "Inga mellanslag före eller efter texten",
                requiredField: "{fieldName} är ett obligatoriskt fält",
                emailError: "Ange en giltig e-postadress (example@gmail.com)",
                postalCodeError: "Ange ett giltigt postnummer (12345, 123 45)",
                personNumberError: "Ange ett giltigt personnummer (YYYYMMDD-XXXX)",
                organisationNumberError: "Ange ett giltigt organisationsnummer (123456-7890)",
                addressLengthRestriction: "Adressen får inte överstiga 90 tecken",
                mobileNumberError: "Telefonnummer har fel format",
                ukPostalCodeError: "Postnummer måste ha rätt format (PE 284P,L18JQ,PE28 4PW)",
            },
            location: {
                selectLocationType: "Välj en platstyp"
            },
            locationName: {
                selectLocationName: "Välj ett platsnamn"
            },
            placeId: {
                selectPlace: "Lägg till en plats"
            },
            contact: {
                selectPersonType: "Välj typ"
            },
            noPermissionToDoChanges: "Du har inte behörighet att göra ändringarna",
            cannotSetAlphabets: "Kan ej ange bokstäver",
            noOrdersSelected: "Minst ett uppdrag krävs för att skicka e-post",
            imageSizeWarning: "Bildens höjd och bredd måste vara minst {SIZE_LENGTH}px",
            productMissingInOrder: "Ett uppdrag måste ha minst en produkt",
            quantityMissing: "Produktantal måste vara valt",
            priceMissing: "Produktpris måste vara valt",
            invalidPercentage: "Procentvärdet ska vara mellan 0 och 100"
        },
        errorMessages: {
            user: {
                userIsNotActive: "Användaren är inte aktiverad. Kontakta systemadministratören.",
                passwordsDoNotMatch: "Lösenorden matchar inte",
                invalidEmailAddress: "Detta verkar vara en ogiltig e-postadress",
                selectUserRole: "Välj användarroll",
                invalidCredentials: "Kunde inte logga in. Kontrollera dina användaruppgifter!"
            },
            product: {
                selectProductType: "Välj produkttyp",
                specifyProductName: "Skriv ett produktnamn",
                selectProductCategory: "Välj produktkategori"
            },
            case: {
                parishNameNotFound: "Ingen församling hittad"
            }
        },
        log: {
            showDetails: "Visa detaljer",
            hideDetails: "Dölj detaljer",
            unknown: "Loggmeddelandet kunde inte visas",
            caseAdded: "Nytt ärende",
            caseRemoved: "Ärende borttaget",
            statusUpdated: "Status uppdaterad",
            caseAssigned: "Ärende tilldelat",
            caseCollaborator: "Medarbetare",
            caseOwnerUpdated: "Ärendets ägare uppdaterad",
            labelAdded: "Ny etikett",
            labelsAdded: "Nya etiketter",
            labelRemoved: "Etikett borttagen",
            labelsRemoved: "Etiketter borttagna",
            deceasedUpdated: "Avliden uppdaterad",
            contactAdded: "Ny kontakt",
            contactUpdated: "Kontakt uppdaterad",
            mainContactUpdated: "Kontaktperson uppdaterad",
            beneficiaryRemoved: "Dödsbodelägare borttagen",
            contactRemoved: "Kontakt borttagen",
            registrationAdded: "Ny anmälan",
            registrationUpdated: "Anmälan uppdaterad",
            registrationRemoved: "Anmälan borttagen",
            emailSent: "E-post skickat",
            todoAdded: "Ny todo",
            todoUpdated: "Todo uppdaterad",
            todoDone: "Todo klar",
            todoRemoved: "Todo borttagen",
            orderAdded: "Nytt uppdrag",
            orderUpdated: "Uppdrag uppdaterat",
            orderRemoved: "Uppdrag borttaget",
            callAdded: "Nytt samtal",
            callRemoved: "Samtal borttaget",
            noteAdded: "Ny anteckning",
            noteRemoved: "Anteckning borttagen",
            documentAdded: "Nytt dokument",
            documentUpdated: "Dokument uppdaterat",
            documentRemoved: "Dokument borttaget",
            documentUploaded: "Dokument uppladdad",
            documentEmailSent: "Dokumentmail skickat",
            bcUpdated: "BC uppdaterat",
            bcError: "BC-fel",
            probateReviewer: "Granskare",
            probateCaseConnected: "Bouppteckning kopplades",
            lossReason: "Tappad",
            messageAdded: "Nytt meddelande",
            updatedMessage: "Meddelande uppdaterat",
            messageRemoved: "Meddelande borttaget",
            filter: {
                event: "Händelse",
                person: "Person",
                dateFrom: "Datum",
                dateTo: "",
                search: "Detaljer",
                placeholder: {
                    event: "Ingen vald",
                    person: "Ingen vald",
                    dateFrom: "Från...",
                    dateTo: "Till...",
                    search: "Skriv för att söka..."
                }
            },
            column: {
                timestamp: "Datum/tid",
                event: "Händelse",
                details: "Detaljer",
                user: "Användare"
            },
            key: {
                unknown: "–",
                type: "Typ",
                order: "Uppdrag",
                recipient: "Mottagare",
                email: "E-post",
                message: "Meddelande",
                deadline: "Deadline",
                version: "Version"
            },
            value: {
                none: "–",
                unknown: "–"
            }
        },
        yesNoUnknownSelectOptions: {
            yes: "Ja",
            no: "Nej",
            unknown: "Okänt"
        },
        yesNoSelectOptions: {
            yes: "Ja",
            no: "Nej",
        },
        stageValidation: {
            generalMessage: "[Case stage cannot be changed. Please action the below:]",
            deliverStageRequiresServiceDate: "[Committal/Burial date has not been entered in the Order tab. Please complete this field before proceeding.]"
        }
    }
}

export default Swedish;
