import Utility from '../../api/utilLanguage';
import {cloneDeep} from 'lodash';
import AppUtil, {NOT_FOUND} from "./appUtil";
import _ from "underscore";
import CaseUtil from "./caseUtil";
import {EventAttribute} from "../case/caseTenantBasedOrder/ukTenantOrderAttributes";
import {getOrderStages, getTenantBasedOrderTypes, getTenantId, UK_TENANT} from "./localStorageUtil";
import AccessControl from '../../api/accessControl';
import OrderDraggingUtil from './orderDraggingUtil';

export const OrderProps = {
    orderCardNote: "orderCardNote",
    worklogPdfDetails: "worklogPdfDetails",
    pricePlace: "pricePlace"
};

class OrderUtil {

    static ORDER_DEFAULT_INDEX = -1;
    static ORDER_DEFAULT_SOURCE = 'case';
    static DISPLAY_NONE = 'none';
    static COLLAPSE_EXPAND_STATUS = 'expand';
    static COLLAPSE_CLOSE_STATUS = 'collapse';
    static ORDER_INITIAL_VALUE = 0;

    static getOrderStages() {
        return {
            draft: {
                valueId: -1,
                text: this.findOrderStageFromLS(-1).value,
                value: -1,
                selected: false
            },
            proposal: {
                valueId: 1,
                text: this.findOrderStageFromLS(1).value,
                value: 1,
                selected: false
            },
            order: {
                valueId: 2,
                text: this.findOrderStageFromLS(2).value,
                value: 2,
                selected: false
            },
            invoice: {
                valueId: 3,
                text: this.findOrderStageFromLS(3).value,
                value: 3,
                selected: false
            },
            archived: {
                valueId: 4,
                text: this.findOrderStageFromLS(4).value,
                value: 4,
                selected: false
            },
            deleted: {
                valueId: 5,
                text: this.findOrderStageFromLS(5).value,
                value: 5,
                selected: false
            },
            onHold: {
                valueId: 6,
                text: this.findOrderStageFromLS(6).value,
                value: 6,
                selected: false
            }
        };
    }

    static orderStages = {
        draft: {
            valueId: -1,
            value: -1,
            selected: false
        },
        proposal: {
            valueId: 1,
            value: 1,
            selected: false
        },
        order: {
            valueId: 2,
            value: 2,
            selected: false
        },
        invoice: {
            valueId: 3,
            value: 3,
            selected: false
        },
        archived: {
            valueId: 4,
            value: 4,
            selected: false
        },
        deleted: {
            valueId: 5,
            value: 5,
            selected: false
        },
        onHold: {
            valueId: 6,
            value: 6,
            selected: false
        }
    };

    static getFlatOrderStages() {
        return [
            OrderUtil.getOrderStages().draft,
            OrderUtil.getOrderStages().proposal,
            OrderUtil.getOrderStages().order,
            OrderUtil.getOrderStages().invoice,
            OrderUtil.getOrderStages().deleted,
            OrderUtil.getOrderStages().archived,
            OrderUtil.getOrderStages().onHold
        ]
    }

    static getOrderTextBasedOnStageId(stageId) {
        if (stageId !== undefined && stageId !== null) {
            let orderStageBasedOnStageId = OrderUtil.getFlatOrderStages().find(function (item) {
                return item.value === stageId;
            });
            return orderStageBasedOnStageId !== undefined && orderStageBasedOnStageId !== null ? orderStageBasedOnStageId.text : "";
        } else {
            return "";
        }
    }

    static getOrderTypes() {
        return ({
            funeralWithCeremony: {
                valueId: 1,
                text: this.findOrderTypeFromLS(1).name,
                textShort: Utility.getLang().staticData.stages.orderType.funeralWithCeremony.textShort,
                value: 1,
                selected: false,
                icon: "order-funeral"
            },
            funeralWithoutCeremony: {
                valueId: 2,
                text: this.findOrderTypeFromLS(2).name,
                textShort: Utility.getLang().staticData.stages.orderType.funeralWithoutCeremony.textShort,
                value: 2,
                selected: false,
                icon: "order-funeral"
            },
            transport: {
                valueId: 3,
                text: this.findOrderTypeFromLS(4).name,
                value: 4,
                selected: false,
                icon: "order-transport"
            },
            flowers: {
                valueId: 4,
                text: this.findOrderTypeFromLS(8).name,
                value: 8,
                selected: false,
                icon: "order-flowers"
            },
            funeralLaw: {
                valueId: 5,
                text: this.findOrderTypeFromLS(16).name,
                value: 16,
                selected: false,
                icon: "order-law"
            },
            familyLaw: {
                valueId: 6,
                text: this.findOrderTypeFromLS(32).name,
                value: 32,
                selected: false,
                icon: "order-law"
            },
            clearingAndCleaning: {
                valueId: 7,
                text: this.findOrderTypeFromLS(64).name,
                value: 64,
                selected: false,
                icon: "order-cleaning"
            },
            tombstone: {
                valueId: 8,
                text: this.findOrderTypeFromLS(128).name,
                value: 128,
                selected: false,
                icon: "order-tombstone"
            },
            otherOrder: {
                valueId: 9,
                text: this.findOrderTypeFromLS(256).name,
                value: 256,
                selected: false,
                icon: "order"
            },
            attendedFuneral: {
                valueId: 10,
                text: this.findOrderTypeFromLS(257).name,
                value: 257,
                selected: false,
                icon: "order"
            },
            unattendedFuneral: {
                valueId: 11,
                text: this.findOrderTypeFromLS(258).name,
                value: 258,
                selected: false,
                icon: "order"
            },
            probate: {
                valueId: 18,
                text: this.findOrderTypeFromLS(259).name,
                value: 259,
                selected: false,
                icon: "order-law"
            },
            repatriation: {
                valueId: 19,
                text: this.findOrderTypeFromLS(260).name,
                value: 260,
                selected: false,
                icon: "order"
            },
            shiftOfEstate: {
                valueId: 20,
                text: this.findOrderTypeFromLS(261).name,
                value: 261,
                selected: false,
                icon: "order-law"
            },
            estateManagement: {
                valueId: 21,
                text: this.findOrderTypeFromLS(262).name,
                value: 262,
                selected: false,
                icon: "order-law"
            },
            will: {
                valueId: 22,
                text: this.findOrderTypeFromLS(263).name,
                value: 263,
                selected: false,
                icon: "order-law"
            },
            storeOfWill: {
                valueId: 23,
                text: this.findOrderTypeFromLS(264).name,
                value: 264,
                selected: false,
                icon: "order-law"
            },
            legalCounsel: {
                valueId: 24,
                text: this.findOrderTypeFromLS(265).name,
                value: 265,
                selected: false,
                icon: "order-law"
            },
            legalAdvice: {
                valueId: 25,
                text: this.findOrderTypeFromLS(266).name,
                value: 266,
                selected: false,
                icon: "order-law"
            },
            estateAdmin: {
                valueId: 26,
                text: this.findOrderTypeFromLS(267).name,
                value: 267,
                selected: false,
                icon: "order-law"
            },
            custodian: {
                valueId: 27,
                text: this.findOrderTypeFromLS(268).name,
                value: 268,
                selected: false,
                icon: "order-law"
            },
            partnerAgreement: {
                valueId: 28,
                text: this.findOrderTypeFromLS(269).name,
                value: 269,
                selected: false,
                icon: "order-law"
            },
            promissoryNote: {
                valueId: 29,
                text: this.findOrderTypeFromLS(270).name,
                value: 270,
                selected: false,
                icon: "order-law"
            },
            coOwnershipAgreement: {
                valueId: 30,
                text: this.findOrderTypeFromLS(271).name,
                value: 271,
                selected: false,
                icon: "order-law"
            },
            prenuptial: {
                valueId: 31,
                text: this.findOrderTypeFromLS(272).name,
                value: 272,
                selected: false,
                icon: "order-law"
            },
            deedOfGift: {
                valueId: 32,
                text: this.findOrderTypeFromLS(273).name,
                value: 273,
                selected: false,
                icon: "order-law"
            },
            lastingPOA: {
                valueId: 33,
                text: this.findOrderTypeFromLS(274).name,
                value: 274,
                selected: false,
                icon: "order-law"
            },
            coBorrowerAgreement: {
                valueId: 34,
                text: this.findOrderTypeFromLS(275).name,
                value: 275,
                selected: false,
                icon: "order-law"
            },
            otherLegalServices: {
                valueId: 35,
                text: this.findOrderTypeFromLS(276).name,
                value: 276,
                selected: false,
                icon: "order-law"
            }
        });
    }

    static orderTypes = {
        funeralWithCeremony: {
            valueId: 1,
            value: 1,
            selected: false,
            icon: "order-funeral"
        },
        funeralWithoutCeremony: {
            valueId: 2,
            value: 2,
            selected: false,
            icon: "order-funeral"
        },
        transport: {
            valueId: 3,
            value: 4,
            selected: false,
            icon: "order-transport"
        },
        flowers: {
            valueId: 4,
            value: 8,
            selected: false,
            icon: "order-flowers"
        },
        funeralLaw: {
            valueId: 5,
            value: 16,
            selected: false,
            icon: "order-law"
        },
        familyLaw: {
            valueId: 6,
            value: 32,
            selected: false,
            icon: "order-law"
        },
        clearingAndCleaning: {
            valueId: 7,
            value: 64,
            selected: false,
            icon: "order-cleaning"
        },
        tombstone: {
            valueId: 8,
            value: 128,
            selected: false,
            icon: "order-tombstone"
        },
        otherOrder: {
            valueId: 9,
            value: 256,
            selected: false,
            icon: "order"
        },
        attendedFuneral: {
            valueId: 10,
            value: 257,
            selected: false,
            icon: "order"
        },
        unattendedFuneral: {
            valueId: 11,
            value: 258,
            selected: false,
            icon: "order"
        },
        probate: {
            valueId: 18,
            value: 259,
            selected: false,
            icon: "order-law"
        },
        repatriation: {
            valueId: 19,
            value: 260,
            selected: false,
            icon: "order"
        },
        shiftOfEstate: {
            valueId: 20,
            value: 261,
            selected: false,
            icon: "order-law"
        },
        estateManagement: {
            valueId: 21,
            value: 262,
            selected: false,
            icon: "order-law"
        },
        will: {
            valueId: 22,
            value: 263,
            selected: false,
            icon: "order-law"
        },
        storeOfWill: {
            valueId: 23,
            value: 264,
            selected: false,
            icon: "order-law"
        },
        legalCounsel: {
            valueId: 24,
            value: 265,
            selected: false,
            icon: "order-law"
        },
        legalAdvice: {
            valueId: 25,
            value: 266,
            selected: false,
            icon: "order-law"
        },
        estateAdmin: {
            valueId: 26,
            value: 267,
            selected: false,
            icon: "order-law"
        },
        custodian: {
            valueId: 27,
            value: 268,
            selected: false,
            icon: "order-law"
        },
        partnerAgreement: {
            valueId: 28,
            value: 269,
            selected: false,
            icon: "order-law"
        },
        promissoryNote: {
            valueId: 29,
            value: 270,
            selected: false,
            icon: "order-law"
        },
        coOwnershipAgreement: {
            valueId: 30,
            value: 271,
            selected: false,
            icon: "order-law"
        },
        prenuptial: {
            valueId: 31,
            value: 272,
            selected: false,
            icon: "order-law"
        },
        deedOfGift: {
            valueId: 32,
            value: 273,
            selected: false,
            icon: "order-law"
        },
        lastingPOA: {
            valueId: 33,
            value: 274,
            selected: false,
            icon: "order-law"
        },
        coBorrowerAgreement: {
            valueId: 34,
            value: 275,
            selected: false,
            icon: "order-law"
        },
        otherLegalServices: {
            valueId: 35,
            value: 276,
            selected: false,
            icon: "order-law"
        }
    };

    static getTypeOfService(orderType) {
        return (orderType === OrderUtil.orderTypes.attendedFuneral.value) ? EventAttribute.attendedFuneralServiceTypeOfService : EventAttribute.funeralServiceTypeOfService;
    }

    static orderBillingStatus = {
        active: {
            id: 1,
            text: "Active"
        },
        paused: {
            id: 2,
            text: "Paused"
        },
        credited: {
            id: 3,
            text: "Credited"
        }
    };

    static eventTypes = {
        ceremony: {
            value: 1
        },
        entombment: {
            value: 2
        },
        reception: {
            value: 4
        },
        preparation: {
            value: 8
        },
        anonymous: {
            value: 16
        },
        preFuneral: {
            value: 32
        },
        funeralService: {
            value: 64
        },
        postFuneral: {
            value: 128
        },
        disbursements: {
            value: 256
        },
        repatriation: {
            value: 512
        },
        memorialService: {
            value: 1024
        }
    };

    static getFlatOrderTypes() {
        return [
            OrderUtil.getOrderTypes().funeralWithCeremony,
            OrderUtil.getOrderTypes().funeralWithoutCeremony,
            OrderUtil.getOrderTypes().transport,
            OrderUtil.getOrderTypes().flowers,
            OrderUtil.getOrderTypes().funeralLaw,
            OrderUtil.getOrderTypes().familyLaw,
            OrderUtil.getOrderTypes().clearingAndCleaning,
            OrderUtil.getOrderTypes().tombstone,
            OrderUtil.getOrderTypes().otherOrder,
            OrderUtil.getOrderTypes().attendedFuneral,
            OrderUtil.getOrderTypes().unattendedFuneral,
            OrderUtil.getOrderTypes().probate,
            OrderUtil.getOrderTypes().repatriation,
            OrderUtil.getOrderTypes().shiftOfEstate,
            OrderUtil.getOrderTypes().estateManagement,
            OrderUtil.getOrderTypes().will,
            OrderUtil.getOrderTypes().storeOfWill,
            OrderUtil.getOrderTypes().legalCounsel,
            OrderUtil.getOrderTypes().legalAdvice,
            OrderUtil.getOrderTypes().estateAdmin,
            OrderUtil.getOrderTypes().custodian,
            OrderUtil.getOrderTypes().partnerAgreement,
            OrderUtil.getOrderTypes().promissoryNote,
            OrderUtil.getOrderTypes().coOwnershipAgreement,
            OrderUtil.getOrderTypes().prenuptial,
            OrderUtil.getOrderTypes().deedOfGift,
            OrderUtil.getOrderTypes().lastingPOA,
            OrderUtil.getOrderTypes().coBorrowerAgreement,
            OrderUtil.getOrderTypes().otherLegalServices
        ]
    }

    /***
     * @description: read order type from local storage
     */
    static findOrderTypeFromLS(idToMatch, fromLSItems = getTenantBasedOrderTypes()) {
        const propertyToMatch = "id";
        if (AppUtil.isAvailable(fromLSItems)) {
            const match = fromLSItems.find((item) => {
                return (item[propertyToMatch] === idToMatch)
            });
            return AppUtil.isAvailable(match) ? match : this.defaultOrderType(idToMatch);
        }
        return this.defaultOrderType(idToMatch);
    }

    /***
     * @description: read order stages from local storage
     */
    static findOrderStageFromLS(idToMatch, fromLSItems = getOrderStages()) {
        const propertyToMatch = "key";
        if (AppUtil.isAvailable(fromLSItems)) {
            const match = fromLSItems.find((item) => {
                return (item[propertyToMatch] === idToMatch)
            });
            return AppUtil.isAvailable(match) ? match : this.defaultOrderStage(idToMatch);
        }
        return this.defaultOrderStage(idToMatch);
    }

    static defaultOrderType(id) {
        return {id: id, name: "", description: null, disabled: false, icon: null};
    }

    static defaultOrderStage(id) {
        return {key: id, value: ""};
    }

    static filterTenantBasedOrderTypes(orderTypes) {
        let a = [];
        if (AppUtil.isAvailable(orderTypes)) {
            orderTypes.forEach(orderType => {
                a.push(this.find(orderType.id))
            });
        }
        return a;
    }

    static getDefaultFilterOrderTypes() {
        return [
            {
                valueId: 1,
                text: Utility.getLang().orders.defaultOrderTypeFilter.funeral,
                textShort: Utility.getLang().staticData.stages.orderType.funeralWithCeremony.textShort,
                value: 1000001,
                selected: false,
                icon: "order-funeral"
            },
            {
                valueId: 2,
                text: Utility.getLang().orders.defaultOrderTypeFilter.legal,
                value: 1000002,
                selected: false,
                icon: "order-law"
            }
        ];
    }

    static find(orderTypeId) {
        let order = this.getFlatOrderTypes().filter(orderType => {
            return orderType.value === orderTypeId;
        });
        if (AppUtil.isAvailable(order)) {
            return order[0];
        }
        return {};
    }

    static getOrderStagesWithSelection(selectedStages) {
        let copyOfStages = cloneDeep(OrderUtil.getFlatOrderStages());
        copyOfStages.forEach(function (stage) {
            if (selectedStages.includes(stage.valueId)) {
                stage.selected = true;
            }
        });
        return copyOfStages;
    }

    static getFilterOrderTypeForCaseColumn(selectedOrderType) {
        let tenantBasedOrderTypes = getTenantBasedOrderTypes();
        let sortedFilteredOrderTypes = OrderUtil.getDefaultFilterOrderTypes();
        let funeralIdValue = sortedFilteredOrderTypes[0].value;

        if (AppUtil.isAvailable(tenantBasedOrderTypes)) {
            tenantBasedOrderTypes.forEach(orderType => {
                sortedFilteredOrderTypes.push(this.find(orderType.id))
            });
        }
        sortedFilteredOrderTypes.forEach(function (orderType) {
            if (selectedOrderType.includes(orderType.valueId)) {
                orderType.selected = true;
            }
        });

        const groupedOrderTypes = _.groupBy(sortedFilteredOrderTypes, (item) => {
            return item.value < funeralIdValue;
        });

        sortedFilteredOrderTypes = _.map(groupedOrderTypes, (value, key) => ({
            label: key === "true" ? Utility.getLang().orders.allOrderTypes : "",
            value: key,
            options: value
        }))

        return sortedFilteredOrderTypes;
    }

    static getOrderTextBasedOnOrderType(orderType, short = false) {
        let orderText = "";
        if (AppUtil.isAvailable(orderType)) {
            let orderBasedOnOrderType = OrderUtil.getFlatOrderTypes().find(function (item) {
                return item.value === orderType;
            });

            if (AppUtil.isAvailable(orderBasedOnOrderType)) {
                orderText = (short === true && AppUtil.isAvailable(orderBasedOnOrderType.textShort)) ? orderBasedOnOrderType.textShort : orderBasedOnOrderType.text;
            }
        }
        return orderText;
    }

    static getOrderIconBasedOnOrderType(orderType) {
        let icon = "order";
        if (AppUtil.isAvailable(orderType)) {
            let orderIconBasedOnOrderType = OrderUtil.getFlatOrderTypes().find(function (item) {
                return item.value === orderType;
            });
            if (AppUtil.isAvailable(orderIconBasedOnOrderType) && AppUtil.isAvailable(orderIconBasedOnOrderType.icon)) {
                icon = orderIconBasedOnOrderType.icon;
            }
        }
        return icon;
    }

    static deliveryMethods = {
        notAvailable: {//FYI: Internal use only, during multiple orders stage change from case(multi-resolution popup)
            value: -1000,
        },
        none: {
            value: 0,
        },
        emailLetter: {
            value: 1,
        },
        email: {
            value: 2,
        },
        smsLetter: {
            value: 3,
        },
        sms: {
            value: 4,
        },
        letter: {
            value: 5,
        },
        efaktura: {
            value: 6,
        },
        paypal: {
            value: 7,
        },
        invoice: {
            value: 8,
        },
        stripe: {
            value: 9,
        }
    };

    static svDeliveryMethods = [
        OrderUtil.deliveryMethods.none.value,
        OrderUtil.deliveryMethods.emailLetter.value,
        OrderUtil.deliveryMethods.email.value,
        OrderUtil.deliveryMethods.smsLetter.value,
        OrderUtil.deliveryMethods.sms.value,
        OrderUtil.deliveryMethods.letter.value,
        OrderUtil.deliveryMethods.efaktura.value
    ]
    static ukDeliveryMethods = [
        OrderUtil.deliveryMethods.paypal.value,
        OrderUtil.deliveryMethods.invoice.value,
        OrderUtil.deliveryMethods.stripe.value
    ]

    /***
     * @description: Sales order helper
     */
    static salesOrderStatus = {
        active: 1,
        paused: 2,
        credited: 3
    }

    static getActiveSalesOrder(salesOrders) {
        if (AppUtil.isAvailable(salesOrders)) {
            const resultSaleOrder = salesOrders.find((saleOrder) => {
                return ((saleOrder.status === OrderUtil.salesOrderStatus.active) || (saleOrder.status === OrderUtil.salesOrderStatus.paused));
            })
            return AppUtil.isAvailable(resultSaleOrder) ? resultSaleOrder : null;
        } else {
            return null;
        }
    }

    static getDisbursementPaymentOptions() {
        return [
            {
                id: 1,
                name: Utility.getLang().orders.disbursementPaymentOptions.customer
            },
            {
                id: 2,
                name: Utility.getLang().orders.disbursementPaymentOptions.funeralPartnerByCheque
            },
            OrderUtil.funeralPartnerOther(),
            {
                id: 4,
                name: Utility.getLang().orders.disbursementPaymentOptions.invoicedToFenix
            },
            {
                id: 5,
                name: Utility.getLang().orders.disbursementPaymentOptions.fenixByPhone
            },
            {
                id: 6,
                name: Utility.getLang().orders.disbursementPaymentOptions.fDChequeAtFuneral
            }
        ];
    }

    static defaultPaymentForRepatriationDisbursementEvent() {
        return OrderUtil.funeralPartnerOther();
    }

    static funeralPartnerOther() {
        return {
            id: 3,
            name: Utility.getLang().orders.disbursementPaymentOptions.funeralPartnerOther
        }
    }

    //To validate order stage type
    static isDraft(target) {
        return (parseInt(target, 10) === OrderUtil.orderStages.draft.value);
    }

    static isProposal(target) {
        return (parseInt(target, 10) === OrderUtil.orderStages.proposal.value);
    }

    static isOrder(target) {
        return (parseInt(target, 10) === OrderUtil.orderStages.order.value);
    }

    static isInvoice(target) {
        return (parseInt(target, 10) === OrderUtil.orderStages.invoice.value);
    }

    static isArchived(target) {
        return (parseInt(target, 10) === OrderUtil.orderStages.archived.value);
    }

    static isDeleted(target) {
        return (parseInt(target, 10) === OrderUtil.orderStages.deleted.value);
    }

    static isOnHold(target) {
        return (parseInt(target, 10) === OrderUtil.orderStages.onHold.value);
    }

    static lockProducts(target) {
        return this.isInvoice(target) || this.isArchived(target) || this.isDeleted(target);
    }

    static lockEventAttributes(target) {
        return this.isDeleted(target);
    }

    static toValidateDeliveryMethod(oldStage, newStage) {
        return (OrderUtil.isInvoice(newStage) || (OrderUtil.isInvoice(oldStage) && OrderUtil.isOrder(newStage)) || (OrderUtil.isArchived(oldStage) && OrderUtil.isOrder(newStage)));
    }

    static doesOrderStageChangeRequireAdminRole(oldStage, newStage) {
        return (OrderDraggingUtil.invoiceToArchive(oldStage, newStage) ||
            OrderDraggingUtil.invoiceToDeleted(oldStage, newStage) ||
            OrderDraggingUtil.archiveToDraft(oldStage, newStage) ||
            OrderDraggingUtil.archiveToProposal(oldStage, newStage) ||
            OrderDraggingUtil.archiveToOrder(oldStage, newStage))
    }

    static verifyIfUserCanMoveOrderToArchivedOrDeletedStageOrCreditPaidInvoice(oldStage, newStage) {
        let hasAdminRole = false;
        const creditArchiveRule = OrderUtil.isArchived(oldStage) && (OrderUtil.isDraft(newStage) || OrderUtil.isProposal(newStage) || OrderUtil.isOrder(newStage));

        if (OrderUtil.isArchived(newStage) || OrderUtil.isDeleted(newStage) || creditArchiveRule) {
            hasAdminRole = AccessControl.hasAdminRole();
        }

        if ((OrderUtil.isArchived(newStage) && (!hasAdminRole)) ||
            (OrderUtil.isDeleted(newStage) && (!hasAdminRole && OrderUtil.isInvoice(oldStage)))) {
            return false;
        }

        if (creditArchiveRule && (!hasAdminRole)) {
            return false;
        }

        return true;
    }

    /***
     * @description:
     * Admin role mandatory
     1. From Archive to Draft/Proposal/Order
     2. From Invoice to Delete
     3. From any stage To Archive
     */
    static addOldStageOnlyForAdminUser(oldStage, newStage) {
        if (this.verifyIfUserCanMoveOrderToArchivedOrDeletedStageOrCreditPaidInvoice(oldStage, newStage)) {
            return oldStage;
        }
    }

    static addInvoiceForAllRolesExceptFuneralAdvisorAndSalesExternal() {
        if (!AccessControl.hasOnlyFuneralAdvisorOrSalesExternalRole()) {
            return OrderUtil.orderStages.invoice.value;
        }
    }

    static orderEmailTypes = {
        caseAndOrderSummary: 3,
        proposal: 5,
        orderConfirmed: 6,
        orderUpdated: 7,
        invoiceCreated: 8,
        proposalUpdated: 9,
        manualSignup: 28
    };

    static orderEmailMatrix = {
        1: {
            type: OrderUtil.orderEmailTypes.proposal,
            text: Utility.getLang().common.orderEmail.proposal
        },
        2: {
            type: OrderUtil.orderEmailTypes.proposalUpdated,
            text: Utility.getLang().common.orderEmail.proposalUpdated
        },
        3: {
            type: OrderUtil.orderEmailTypes.orderConfirmed,
            text: Utility.getLang().common.orderEmail.confirmed
        },
        4: {
            type: OrderUtil.orderEmailTypes.orderUpdated,
            text: Utility.getLang().common.orderEmail.orderUpdated
        },
        5: {
            type: OrderUtil.orderEmailTypes.invoiceCreated,
            text: Utility.getLang().common.orderEmail.invoiceCreated
        },
        6: {
            type: OrderUtil.orderEmailTypes.manualSignup,
            text: Utility.getLang().common.orderEmail.manualSignup
        }
    };

    static changedOrdersForSendMail = (orders) => {
        if (AppUtil.isAvailable(orders) && (orders.length > 0)) {
            return orders.filter((order, i) => order.anyChangesToSendMail === true);
        } else {
            return null;
        }
    };

    static checkEmailButtonDisableCondition = (orderId) => {
        switch (parseInt(orderId, 10)) {
            case OrderUtil.orderStages.draft.value:
            case OrderUtil.orderStages.deleted.value:
            case OrderUtil.orderStages.archived.value:
            case OrderUtil.orderStages.onHold.value:
                return true;
            case OrderUtil.orderStages.order.value:
            case OrderUtil.orderStages.proposal.value:
            case OrderUtil.orderStages.invoice.value:
                return false;
            default:
                return false;
        }
    };

    static checkEmailTypeDisableCondition = (emailType, stage) => {
        let value = true;
        switch (parseInt(stage, 10)) {
            case OrderUtil.orderStages.draft.value:
            case OrderUtil.orderStages.deleted.value:
            case OrderUtil.orderStages.archived.value:
            case OrderUtil.orderStages.onHold.value:
                return true;
            case OrderUtil.orderStages.order.value:
                if (emailType === OrderUtil.orderEmailTypes.orderConfirmed || emailType === OrderUtil.orderEmailTypes.orderUpdated || emailType === OrderUtil.orderEmailTypes.manualSignup) {
                    value = false;
                }
                return value;
            case OrderUtil.orderStages.proposal.value:
                if (emailType === OrderUtil.orderEmailTypes.proposal || emailType === OrderUtil.orderEmailTypes.proposalUpdated) {
                    value = false;
                }
                return value;
            case OrderUtil.orderStages.invoice.value:
                if (emailType === OrderUtil.orderEmailTypes.invoiceCreated) {
                    value = false;
                }
                return value;
            default:
                return value;
        }
    };

    static checkChangeInOrder = (newData, oldData) => {
        return JSON.stringify(newData) !== JSON.stringify(oldData);
    };

    /***
     *@description: get all dresscode
     */
    static getFormattedAllDresscodeTypesForCreateSelect = (allDresscodeTypes) => {
        const formattedAllDresscodeTypes = allDresscodeTypes.map((dresscodeType) => ({
                ...dresscodeType,
                value1: `${dresscodeType.key}`,
                label: dresscodeType.value
            })
        );
        return formattedAllDresscodeTypes;
    };

    /***
     * @description: Memorial fund helpers
     */
    static getFormattedMemorialFundsForCreateSelect = (memorialFunds) => {
        const formattedMemorialFunds = memorialFunds.map((memorialFund) => ({
                ...memorialFund,
                value: `${memorialFund.id}`,
                label: memorialFund.name
            })
        );
        return formattedMemorialFunds;
    };

    static createGroupSelectionOptionsForMemorialFunds = (memorialFunds) => {
        const createSelectFormattedResult = OrderUtil.getFormattedMemorialFundsForCreateSelect(memorialFunds);
        const groupedOptionsHashMap = _.groupBy(createSelectFormattedResult, (memorialFund) => {
            return memorialFund.id >= OrderUtil.memorialFund.notDecided;
        });

        function getReactSelectTitle(key) {
            return (key === "true") ? "" : Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.allMemorialFunds;
        }

        //For group based options, format it to {label: "grpTitle", options: selectOptions}
        return _.map(groupedOptionsHashMap, (value, key) => ({
            label: getReactSelectTitle(key),
            value: key,//must add key {string} in CreatableSelect
            options: value
        })).reverse();
    };

    static memorialFund = {
        notDecided: 1000001,
        noMemorialFund: 1000002
    };

    static getDefaultMemorialFund = (matchingId, memorialFunds) => {
        const defaultMemorialFundAry = memorialFunds.filter((memorialFund) => {
            return (memorialFund.id === matchingId);
        });

        const defaultValue = (defaultMemorialFundAry.length > 0) ? defaultMemorialFundAry[0] : null;
        return defaultValue;
    };

    static dresscode = {
        notDecided: 1
    };

    static getDefaultDresscodeType = (matchingId, dresscodes) => {
        const defaultMatchedAry = dresscodes.filter((dresscode) => {
            return (dresscode.key === matchingId);
        });

        const defaultValue = (defaultMatchedAry.length > 0) ? defaultMatchedAry[0] : null;
        return defaultValue;
    };

    static updateOrderStage(orders, sourceStage, destStage) {
        if (CaseUtil.isFromLost(sourceStage, destStage)) {
            if (AppUtil.isAvailable(orders) && orders.length > 0) {
                const orderStages = OrderUtil.getOrderStages();
                orders.forEach((order) => {
                    if (order.stage !== orderStages.invoice.value && order.stage !== orderStages.archived.value) {
                        order.stage = orderStages.draft.value;
                        order.stageText = orderStages.draft.text;
                    }
                });
            }
        }

        if (CaseUtil.isPrePlanning(destStage)) {
            if (AppUtil.isAvailable(orders) && orders.length > 0) {
                const orderStages = OrderUtil.getOrderStages();
                orders.forEach((order) => {
                    order.stage = orderStages.onHold.value;
                    order.stageText = orderStages.onHold.text;
                });
            }
        }
    }

    /***
     * @description: Get required event attribute
     */
    static getFuneralServiceEventAttributeToMatch(attributeType, fromEvents) {
        return OrderUtil.getEventAttributeOfMatchingEvent(OrderUtil.eventTypes.funeralService.value, attributeType, fromEvents);
    }

    static getEventAttributeOfMatchingEvent(eventType, attributeType, fromEvents) {
        let eventAttribute = null;
        const event = fromEvents.find((event) => {
            return (event.eventType === eventType);
        });

        if (AppUtil.isAvailable(event)) {
            eventAttribute = event.eventAttributes.find((event) => {
                return (event.attributeType === attributeType);
            });
        }
        return eventAttribute;
    }

    static getMatchedOrderIndex = (orderToMatch, fromOrders) => {
        return fromOrders.findIndex((order) => {
            return order.id === orderToMatch;
        });
    }

    static hasFoundMatchedIndex(matchedIndex) {
        return (matchedIndex !== -1);
    }

    static isFullName = (data) => {
        return AppUtil.isAvailable(data.firstName) && AppUtil.isAvailable(data.lastName);
    };

    static isFullAddress = (address) => {
        return (AppUtil.isAvailable(address) &&
            ((AppUtil.isAvailable(address.address) || AppUtil.isAvailable(address.address2)) &&
                AppUtil.isAvailable(address.city) && AppUtil.isAvailable(address.postalCode)))
    };

    static isUKCountryCode = (address) => {
        const countryCode = 'GB';
        return AppUtil.isAvailable(address) && AppUtil.isAvailable(address.country) && AppUtil.isAvailable(address.country.countryCode) && (address.country.countryCode.toLowerCase() === countryCode.toLowerCase());
    }

    static getOrderDeliveryMethodHelperTextOnTenantBased = (tenantId = getTenantId()) => {
        return `${tenantId === UK_TENANT ? Utility.getLang().common.changeStagePopup.toShowDeliveryMethodsContactMandatoryInfo : ""}`
    };

    static hasNoDeliveryMethods = (deliveryMethods, forSelectedBillingContact) => {
        return (AppUtil.isAvailable(forSelectedBillingContact) && AppUtil.isEmpty(deliveryMethods));
    };

    static hasOrdersWithMandatoryDeliveryMethod = (orders) => {
        return AppUtil.isAvailable(orders) && (orders.every((order) => order.deliveryMethod !== OrderUtil.deliveryMethods.notAvailable.value))
    };

    static pausedUntilDate(salesOrders) {
        const resultOrders = this.filterPausedUntilDates(salesOrders);
        return resultOrders.length > 0 ? resultOrders[0].pausedUntilDate : "";
    }

    static filterPausedUntilDates(salesOrders) {
        return salesOrders.filter(columnDataMapper => {
            return (columnDataMapper.status === 2)
        }).sort(function (obj1, obj2) {
            //Desc: order of id's
            return obj2.id - obj1.id
        });
    }

    static uniqueId = (order, event) => {
        return (AppUtil.isAvailable(order) && AppUtil.isAvailable(event)) ? `${order.id}_${order.orderType}_${event.id}_${event.eventType}` : "orderEventUniqueId";
    };

    static getMatchedEvent = (fromOrders, forOrderId, forEventId, orderIndex, _eventIndex, eventType) => {
        const oldOrder = fromOrders[orderIndex];
        if (oldOrder.id > 0) {//Existing order
            const orderIndex = fromOrders.findIndex(order => order.id === forOrderId);
            if (orderIndex !== NOT_FOUND) {
                const matchedOrder = fromOrders[orderIndex];
                const eventIndex = matchedOrder.events.findIndex(event => {
                    if (event.id > 0) {
                        return event.id === forEventId;
                    } else {//For optional events
                        return event.eventType === eventType;
                    }
                });
                if (eventIndex !== NOT_FOUND) {
                    const matchedEvent = matchedOrder.events[eventIndex];
                    return matchedEvent;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {//For new order added, read by index
            return oldOrder.events[_eventIndex];
        }
    };

    static isNewOrder = (id) => {
        return ((id <= 0) || (id == null));
    }

    static removeItems = (sourceObject, keyToMatch, valueToMatch, index) => {
        return sourceObject.filter((item, i) => !(i === index && item[keyToMatch] === valueToMatch));
    };

    static getCaseWithUpdatedBillingContact = (order) => {
        //Set billing contact in order
        const orderCopy = Object.assign({}, order);
        orderCopy.selectedBillingContact = [];
        orderCopy.case.relatives.forEach((relative) => {
            if (relative.id === orderCopy.billingContactId) {
                orderCopy.selectedBillingContact.push(relative);
            }
        });

        const caseData = {};
        caseData.id = orderCopy.case.id;
        caseData.orders = [orderCopy];
        return caseData;
    };
}

export default OrderUtil;


export const YesNoOptionsOnly = {
    yes: {
        id: 1,
        value: "1"
    },
    no: {
        id: 2,
        value: "2"
    },
};

export const TypeOfAttendedFuneralService = {
    serviceAtLowCost: {
        id: 1,
        value: "1"
    },
    serviceAtBespoke: {
        id: 2,
        value: "2"
    },
    serviceOntoCemetery: {
        id: 3,
        value: "3"
    },
    serviceOntoCrematorim: {
        id: 4,
        value: "4"
    },
    gravesideBurial: {
        id: 5,
        value: "5"
    },
    simpleCremation: {
        id: 6,
        value: "6"
    }
};
/***
 * @description: Legal order type helper
 */
const LegalOrderTypes = [OrderUtil.orderTypes.funeralLaw.value,
    OrderUtil.orderTypes.familyLaw.value,
    OrderUtil.orderTypes.probate.value,
    OrderUtil.orderTypes.coBorrowerAgreement.value,
    OrderUtil.orderTypes.coOwnershipAgreement.value,
    OrderUtil.orderTypes.custodian.value,
    OrderUtil.orderTypes.deedOfGift.value,
    OrderUtil.orderTypes.estateAdmin.value,
    OrderUtil.orderTypes.estateManagement.value,
    OrderUtil.orderTypes.lastingPOA.value,
    OrderUtil.orderTypes.legalAdvice.value,
    OrderUtil.orderTypes.legalCounsel.value,
    OrderUtil.orderTypes.otherLegalServices.value,
    OrderUtil.orderTypes.partnerAgreement.value,
    OrderUtil.orderTypes.prenuptial.value,
    OrderUtil.orderTypes.promissoryNote.value,
    OrderUtil.orderTypes.shiftOfEstate.value,
    OrderUtil.orderTypes.storeOfWill.value,
    OrderUtil.orderTypes.will.value
];

export const isLegalOrderType = (orderType) => {
    return LegalOrderTypes.includes(orderType);
}

function transformArrayIntoSelectOptions(list) {
    return _.map(list, (data) => ({
        label: data.value,
        value: data.key
    }));
}

export function matchItem(items, idToMatch, itemsPropertyToMatch = 'key') {
    if (AppUtil.isAvailable(items)) {
        const match = items.find((item) => {
            return (item[itemsPropertyToMatch] === idToMatch);
        });
        return match;
    } else {
        return undefined;
    }
}

function updateOptions(options, items, idToMatch) {
    const matchedItem = matchItem(items, idToMatch);
    if (AppUtil.isAvailable(matchedItem)) {
        options.push(matchedItem);
    }
}

function validateAndGetBillogramDeliveryMethodValues(selectedRelative) {
    let deliveryMethodValues = [];
    deliveryMethodValues.push(OrderUtil.deliveryMethods.none.value);
    if (AppUtil.isAvailable(selectedRelative.email)) {
        deliveryMethodValues.push(OrderUtil.deliveryMethods.email.value);
    }
    if (AppUtil.isAvailable(selectedRelative.phoneNumber)) {
        deliveryMethodValues.push(OrderUtil.deliveryMethods.sms.value);
    }
    if (AppUtil.isAvailable(selectedRelative.address)) {
        if (OrderUtil.isFullAddress(selectedRelative.address)) {
            deliveryMethodValues.push(OrderUtil.deliveryMethods.letter.value);

            if (deliveryMethodValues.includes(OrderUtil.deliveryMethods.email.value)) {
                deliveryMethodValues.push(OrderUtil.deliveryMethods.emailLetter.value);
            }
            if (deliveryMethodValues.includes(OrderUtil.deliveryMethods.sms.value)) {
                deliveryMethodValues.push(OrderUtil.deliveryMethods.smsLetter.value);
            }
        }
    }
    if (AppUtil.isAvailable(selectedRelative.personNumber)) {
        deliveryMethodValues.push(OrderUtil.deliveryMethods.efaktura.value);
    }
    return deliveryMethodValues;
}

function validateAndGetUkDeliveryMethodValues(selectedRelative) {
    let deliveryMethodValues = [];
    const hasFullNameAndAddress = OrderUtil.isFullName(selectedRelative) && OrderUtil.isFullAddress(selectedRelative.address);
    if (hasFullNameAndAddress) {
        deliveryMethodValues.push(OrderUtil.deliveryMethods.invoice.value);
        deliveryMethodValues.push(OrderUtil.deliveryMethods.stripe.value);
    }
    if (OrderUtil.isUKCountryCode(selectedRelative.address) && hasFullNameAndAddress && AppUtil.isAvailable(selectedRelative.email)) {
        deliveryMethodValues.push(OrderUtil.deliveryMethods.paypal.value);
    }
    return deliveryMethodValues;
}

function billogramDeliveryOptions(selectedRelative, availableDeliveryMethods) {
    const deliveryMethodValues = validateAndGetBillogramDeliveryMethodValues(selectedRelative);
    let options = [];
    deliveryMethodValues.forEach((value) => {
        updateOptions(options, availableDeliveryMethods, value);
    });
    return transformArrayIntoSelectOptions(options);
}

function ukDeliveryOptions(selectedRelative, availableDeliveryMethods) {
    const deliveryMethodValues = validateAndGetUkDeliveryMethodValues(selectedRelative);
    let options = [];
    deliveryMethodValues.forEach((value) => {
        updateOptions(options, availableDeliveryMethods, value);
    });
    return transformArrayIntoSelectOptions(options);
}

export function getOrderDeliveryMethodOptions(selectedRelative, availableDeliveryMethods) {
    if (AppUtil.isAvailable(selectedRelative) && AppUtil.isAvailable(availableDeliveryMethods)) {
        const deliveryMethodToMatch = availableDeliveryMethods[0].key;
        if (OrderUtil.svDeliveryMethods.includes(deliveryMethodToMatch)) {
            return billogramDeliveryOptions(selectedRelative, availableDeliveryMethods);
        } else if (OrderUtil.ukDeliveryMethods.includes(deliveryMethodToMatch)) {
            return ukDeliveryOptions(selectedRelative, availableDeliveryMethods);
        } else {
            return billogramDeliveryOptions(selectedRelative, availableDeliveryMethods);
        }
    } else {
        return [];
    }
}
