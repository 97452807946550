import config from "./config";
import Utility from "./utilLanguage";
import Enum from "../components/common/enum";
import {getTenantBasedLocationTypes} from "../components/common/localStorageUtil";

class locationService {

    static getLocationPageColumnsConfiguration() {
        return {
            columns: [
                {
                    name: Utility.getLang().locations.locations,
                    id: "locations",
                    dataSourceUrl: config.baseURL + "api/v1/location/list?ues=true&psi=0&pl=25",
                    dataSourceUrlRequestParameters: "&sort=s_id:asc&f_lid=1&f_lid=2&f_lid=4&f_lid=8&f_lid=16&f_lid=32&f_lid=64&f_lid=128&f_lid=256&f_lid=512&f_lid=1024",
                    columnItemDetailUrl: config.baseURL + "api/v1/location/",
                    headerIconFileName: "group-location--color",
                    itemsPropertyName: "locations",
                    creatable: true,
                    filter: [
                        {
                            "filterId": 1,
                            "type": "text",
                            "text": Utility.getLang().staticData.filter.freeText.text,
                            "description": Utility.getLang().staticData.filter.freeText.description,
                            "key": "f_tx"
                        },
                        {
                            "filterId": 2,
                            "type": "multipleAsync",
                            "dataUrl": config.baseURL + "api/v1/location/place/list",
                            "text": Utility.getLang().staticData.filter.locationPlace.text,
                            "description": Utility.getLang().staticData.filter.locationPlace.description,
                            "key": "f_lpl",
                            "asyncKey": "f_pn"
                        },
                        {
                            "filterId": 3,
                            "type": "multipleWithStaticValues",
                            "text": Utility.getLang().staticData.filter.locationType.text,
                            "description": Utility.getLang().staticData.filter.locationType.description,
                            "key": "f_lid",
                            "values": getTenantBasedLocationTypes()
                        }
                    ],
                    sort: {
                        "text": "Sort",
                        "description": "Sort on different attributes",
                        "key": "sort",
                        "values": [
                            {
                                "valueId": 1,
                                "text": Utility.getLang().staticData.sort.idAsc.text,
                                "description": Utility.getLang().staticData.sort.idAsc.description,
                                "value": "s_id:asc",
                                "selected": true
                            },
                            {
                                "valueId": 2,
                                "text": Utility.getLang().staticData.sort.idDesc.text,
                                "description": Utility.getLang().staticData.sort.idDesc.description,
                                "value": "s_id:desc",
                                "selected": false
                            },
                            {
                                "valueId": 3,
                                "text": Utility.getLang().staticData.sort.locationNameAsc.text,
                                "description": Utility.getLang().staticData.sort.locationNameAsc.description,
                                "value": "s_ln:asc",
                                "selected": false
                            },
                            {
                                "valueId": 4,
                                "text": Utility.getLang().staticData.sort.locationNameDesc.text,
                                "description": Utility.getLang().staticData.sort.locationNameDesc.description,
                                "value": "s_ln:desc",
                                "selected": false
                            }
                        ]
                    }
                }
            ],
            cardDetailViewTabCollection: [
                {
                    shortcut: "A",
                    description: Utility.getLang().locations.tabs.details,
                    childComponent: Enum.LocationDetails,
                    selected: true
                },
                {
                    shortcut: "S",
                    description: Utility.getLang().locations.tabs.map,
                    childComponent: Enum.LocationMap
                },
                {
                    shortcut: "D",
                    description: Utility.getLang().locations.tabs.types,
                    childComponent: Enum.LocationCeremonyType
                },
                {
                    shortcut: "F",
                    description: Utility.getLang().locations.tabs.equipment,
                    childComponent: Enum.LocationEquipment
                },
                {
                    shortcut: "G",
                    description: Utility.getLang().locations.tabs.contact,
                    childComponent: Enum.LocationContact
                }
            ]
        };
    }

    static locationTypes = {
        country: {
            text: Utility.getLang().locations.locationTypes.country,
            value: 1
        },
        county: {
            text: Utility.getLang().locations.locationTypes.county,
            value: 2
        },
        municipality: {
            text: Utility.getLang().locations.locationTypes.municipality,
            value: 4
        },
        city: {
            text: Utility.getLang().locations.locationTypes.city,
            value: 8
        }
    }

    static transformLocationTypesIntoFilterOptions(arr) {
        let a = [];
        arr.forEach((element, index) => {
            a.push({
                valueId: index + 1,
                text: element.value,
                description: element.value,
                value: element.key + "",
                selected: true
            });
        });
        return a;
    }
}

export default locationService;
