import config from "./config";

export const LocationTypes = {
    ceremonyLocation: 1,
    graveyards: 2,
    parishHome: 4,
    hospital: 8,
    custom: 16,
    crematorium: 32,
    naturalBurialGround: 64,
    funeralDirector: 128,
    chapel: 256,
    coldRoom: 512,
    externalOffice: 1024
}

export default class searchService {
    static pageLimit = 25;
    static searchInitialUrlConfiguration = {
        initialCaseUrl: config.baseURL + `api/v1/case/list?ues=true&psi=0&pl=${searchService.pageLimit}&sort=s_ranking:asc&exactsearch=true&f_tx=`,
        initialLocationUrl: config.baseURL + `api/v1/location/list?ues=true&psi=0&pl=${searchService.pageLimit}&sort=s_ln:asc&exactsearch=true&f_tx=`,
        initialOrderUrl: config.baseURL + `api/v1/order/list?psi=0&pl=${searchService.pageLimit}&sort=s_id:asc&exactsearch=true&f_tx=`,
        initialCallUrl: config.baseURL + `api/v1/case/call/list?psi=0&pl=${searchService.pageLimit}&sort=s_id:desc&exactsearch=true&f_tx=`,
        initialProductUrl: config.baseURL + `api/v1/product/list?psi=0&pl=${searchService.pageLimit}&sort=s_pn:asc&exactsearch=true&f_tx=`,
        initialContactUrl: config.baseURL + `api/v1/person/list?psi=0&pl=${searchService.pageLimit}&sort=s_cn:asc&exactsearch=true&f_tx=`
    };

    static getImagesOnLocationType(type) {
        switch (type) {
            case 1:
                return require('../icons/map_marker-ceremony.svg');
            case 2:
                return require('../icons/map_marker-graveyard.svg');
            case 4:
                return require('../icons/map_marker-parish.svg');
            case 8:
                return require('../icons/map_marker-hospital.svg');
            default: //Do Nothing
        }

    };

    static getImagesOnPersonType(type) {
        switch (type) {
            case 1:
                return require('../icons/map_marker-contact.svg');
            case 2:
                return require('../icons/map_marker-contact.svg');
            case 4:
                return require('../icons/map_marker-contact.svg');
            case 8:
                return require('../icons/map_marker-contact.svg');
            default: //Do Nothing
        }

    }
}
